<mat-toolbar>
  <div class="container">
    <div class="edge">
      <img src="assets/images/logo-orange.png" alt="Logo" id="logo" [routerLink]="['/']"/>
    </div>
    <ul class="nav">
      <app-static-nav-items></app-static-nav-items>
      <app-auth-nav-items></app-auth-nav-items>
    </ul>
  </div>
</mat-toolbar>
