import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {WithId} from '../../../../../shared/interfaces/with-id';
import {ConnectorProviderDocument, ConnectorProviderModel} from '../../../../../shared/types/connector-provider';

@Pipe({
  name: 'connectorsProvider'
})
export class ConnectorsProviderPipe extends DocumentPipe<ConnectorProviderDocument, ConnectorProviderModel> implements PipeTransform {

  transform(doc: WithId<ConnectorProviderDocument>, ...args: any[]): ConnectorProviderModel {
    const transformed = super.transform(doc);

    transformed.logoPath = `assets/images/providers/${doc.logoName}`;

    return transformed;
  }

}
