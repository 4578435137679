import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {EntityLogModel} from '../../../../../shared/types/logs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-logs-table',
  templateUrl: './logs-table.component.html',
  styleUrls: ['./logs-table.component.scss']
})
export class LogsTableComponent implements AfterViewInit {

  displayedColumns: string[] = ['type', 'createdAt'];
  dataSource: MatTableDataSource<EntityLogModel>;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @Input() logs: EntityLogModel[];


  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource(this.logs);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
