import * as firebase from 'firebase/app';
import {PublicOrganizationData} from '../organization';
import {PublicUserData, UserRole} from '../user';

export enum NotificationType {
  Generic = 'generic',
  Invitation = 'invitation',
  InvitationAcceptance = 'invitationAcceptance',
  InvitationDismissal = 'invitationDismissal',
  Removal = 'removal',
  DataStudioTemplateError = 'dataStudioTemplateError',
  AccountTokenExpired = 'accountTokenExpired',
}

export enum NotificationAction {
  Accept = 'accept',
  Decline = 'decline',
  View = 'view'
}

export interface InvitationNotification extends Notification {
  type: NotificationType.Invitation;
  userData?: PublicUserData;
  permission: UserRole;
  organizationData?: PublicOrganizationData;
}

export interface RemovalNotification extends Notification {
  type: NotificationType.Removal;
  userData?: PublicUserData;
  organizationData?: PublicOrganizationData;
}

export interface DataStudioTemplateErrorNotification extends Notification {
  type: NotificationType.DataStudioTemplateError;
  connector: {
    title: string;
    type: string;
  };
  page: number;
  errors: number;
}

export interface Notification {
  type: string;
  to: firebase.firestore.DocumentReference;
  hasRead?: boolean;
  status: undefined | NotificationAction;
}

