import { Component, HostBinding, Input } from '@angular/core';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base/base.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@ngneat/transloco";
const _c0 = ["*"];
export class PageTitleComponent extends BaseComponent {
    constructor(titleService, elRef, translocoService) {
        super();
        this.titleService = titleService;
        this.elRef = elRef;
        this.translocoService = translocoService;
        this.color = null;
        this.siteTitle = 'Power My Analytics';
    }
    ngOnInit() {
        this.translocoService.events$.pipe(filter(e => e.type === 'translationLoadSuccess'), tap(() => setTimeout(this.setTitle.bind(this), 0)), // Wait for one change detection and update the title
        takeUntil(this.ngUnsubscribe)).subscribe();
    }
    ngAfterViewInit() {
        this.setTitle();
    }
    setTitle() {
        const content = this.elRef.nativeElement.innerText.trim();
        if (content.length > 0) {
            this.titleService.setTitle(this.siteTitle + ' - ' + content);
        }
    }
    ngOnDestroy() {
        this.titleService.setTitle(this.siteTitle);
    }
}
PageTitleComponent.ɵfac = function PageTitleComponent_Factory(t) { return new (t || PageTitleComponent)(i0.ɵɵdirectiveInject(i1.Title), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.TranslocoService)); };
PageTitleComponent.ɵcmp = i0.ɵɵdefineComponent({ type: PageTitleComponent, selectors: [["app-page-title"]], hostBindings: function PageTitleComponent_HostBindings(rf, ctx, elIndex) { if (rf & 1) {
        i0.ɵɵallocHostVars(1);
    } if (rf & 2) {
        i0.ɵɵstyleSanitizer(i0.ɵɵdefaultStyleSanitizer);
        i0.ɵɵstyleProp("background", ctx.color);
    } }, inputs: { color: "color" }, features: [i0.ɵɵInheritDefinitionFeature], ngContentSelectors: _c0, decls: 3, vars: 0, consts: [["id", "page-title"], [1, "container"]], template: function PageTitleComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "section", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵprojection(2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } }, styles: ["[_nghost-%COMP%]{background:url(analytics-background.a7158a471d549394e35f.png) center center #00537b;display:block;padding:0 16px 32px}#page-title[_ngcontent-%COMP%]{color:#fff;font-family:\"Product Sans\",Roboto sans-serif;font-size:32px;padding-top:128px}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(PageTitleComponent, [{
        type: Component,
        args: [{
                selector: 'app-page-title',
                templateUrl: './page-title.component.html',
                styleUrls: ['./page-title.component.scss']
            }]
    }], function () { return [{ type: i1.Title }, { type: i0.ElementRef }, { type: i2.TranslocoService }]; }, { color: [{
            type: HostBinding,
            args: ['style.background']
        }, {
            type: Input
        }] }); })();
