<ng-container *transloco="let t; read: 'app.nav'">
  <ng-container *ngFor="let item of menu">
    <li *ngIf="item.children" [matMenuTriggerFor]="menu">
      <span>{{ t('menu.' + item.title + '.title') }}</span>
      <fa-icon *ngIf="item.children" [icon]="['fal', 'chevron-down']"></fa-icon>
      <mat-menu #menu="matMenu">
        <button *ngFor="let child of item.children" mat-menu-item [routerLink]="child.href">
          {{ t('menu.' + item.title + '.children.' + child.title) }}
        </button>
      </mat-menu>
    </li>

    <li *ngIf="!item.children">
      <a [href]="item.href">{{ t('menu.' + item.title + '.title') }}</a>
    </li>
  </ng-container>
</ng-container>
