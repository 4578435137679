<div class="provider {{provider.type}}" [style.backgroundColor]="provider.color.base + '14'">
  <div class="provider-container" matRipple [matRippleColor]="provider.color.base + '8a'">
    <div class="logo-wrapper">
      <div class="badge"
           [style.backgroundColor]="provider.color.base"
           [style.color]="provider.color.contrast"
           [hidden]="!counter[provider.type]">
        {{ counter[provider.type] }}
      </div>
      <app-picture [srcset]="provider.logoPath" [encapsulate]="'height'"></app-picture>
    </div>

    <strong class="provider-name" [style.color]="provider.color.text || provider.color.base">{{ provider.title }}</strong>
  </div>
</div>
