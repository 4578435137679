import {NgModule} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {CoreModule} from '@core/core.module';
import {SharedModule} from '@shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './components/footer/footer.component';
import {AuthNavItemsComponent} from './components/nav/auth-nav-items/auth-nav-items.component';
import {NavComponent} from './components/nav/nav.component';
import {NotificationsComponent} from './components/nav/notifications/notifications.component';
import {StaticNavItemsComponent} from './components/nav/static-nav-items/static-nav-items.component';
import {LogsDialogComponent} from './components/logs-dialog/logs-dialog.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { LogsTableComponent } from './components/logs-dialog/logs-table/logs-table.component';
import {MatSortModule} from '@angular/material/sort';
import { SignInWithGoogleComponent } from './components/nav/auth-nav-items/sign-in-with-google/sign-in-with-google.component';
import { GetStartedComponent } from './components/get-started/get-started.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    FooterComponent,
    AuthNavItemsComponent,
    StaticNavItemsComponent,
    NotificationsComponent,
    LogsDialogComponent,
    LogsTableComponent,
    GetStartedComponent
  ],
  imports: [
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatMenuModule,
    SharedModule,
    CoreModule,
    MatBadgeModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule
  ],
  exports: [
    AuthNavItemsComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
