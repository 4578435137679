import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { orderBy } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@core/services/auth/auth.service";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@shared/pipes/notification/notification.pipe";
export class NotificationsService {
    constructor(auth, afs, notificationPipe) {
        this.auth = auth;
        this.afs = afs;
        this.notificationPipe = notificationPipe;
        /**
         * TODO docs
         */
        this.notifications$ = combineLatest([this.auth.user$, this.organizationId$]).pipe(filter(([user, organizationId]) => Boolean(user)), switchMap(([user, organizationId]) => this.getNotifications(user, organizationId)));
    }
    getNotifications(user, organizationId) {
        const refs = this.getRelevantRefs(user, organizationId);
        return this.afs.collection('notifications', (ref) => ref
            .where('to', 'array-contains-any', refs)
            .limit(NotificationsService.MAX_LIMIT))
            .valueChanges({ idField: 'id' })
            .pipe(map(collection => this.transformCollection(collection)), map(models => orderBy(models, ['updatedAt'], ['desc'])));
    }
    /**
     * TODO docs
     * @param notification NotificationModel
     * @param action NotificationAction
     */
    patchStatus(notification, action) {
        return this.afs.collection('notifications').doc(notification.id).update({
            status: action,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
    }
    /**
     * TODO docs
     */
    markAsRead(notifications) {
        const promises = notifications
            .filter(notification => !notification.hasRead)
            .map(notification => this.afs
            .collection('notifications')
            .doc(notification.id)
            .update({ hasRead: true }));
        return Promise.all(promises);
    }
    getRelevantRefs(user, organizationId) {
        const refs = [this.afs.doc(`users/${user.uid}`).ref];
        if (organizationId) {
            refs.push(this.afs.doc(`organizations/${organizationId}`).ref);
        }
        return refs;
    }
    /**
     * TODO docs
     * @param collection WithId<NotificationDocument>[]
     */
    transformCollection(collection) {
        return collection.map(doc => this.notificationPipe.transform(doc));
    }
}
/**
 * TODO docs
 */
NotificationsService.MAX_LIMIT = 50;
NotificationsService.ɵfac = function NotificationsService_Factory(t) { return new (t || NotificationsService)(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.NotificationPipe)); };
NotificationsService.ɵprov = i0.ɵɵdefineInjectable({ token: NotificationsService, factory: NotificationsService.ɵfac, providedIn: 'root' });
__decorate([
    Select(state => state.organizations ? state.organizations.selectedId : null)
], NotificationsService.prototype, "organizationId$", void 0);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NotificationsService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.AuthService }, { type: i2.AngularFirestore }, { type: i3.NotificationPipe }]; }, { organizationId$: [] }); })();
