import { __awaiter, __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Action, State } from '@ngxs/store';
import { PatchStreamedAuth, RedirectAfterSignIn, RedirectThirdParty, SignInWithGoogle, SignOut, StreamAuth } from '@store/auth/auth.actions';
import { filter, first, tap } from 'rxjs/operators';
import { DisplayError, StartLoading, StopLoading } from '@store/app/app.actions';
import * as i0 from "@angular/core";
import * as i1 from "@core/services/auth/auth.service";
import * as i2 from "@angular/router";
const initialState = {
    isInitialized: false,
    isLoggedIn: undefined,
    user: undefined
};
let AuthState = class AuthState {
    constructor(auth, router) {
        this.auth = auth;
        this.router = router;
    }
    /**
     * On initializing AuthState
     * @param dispatch new action
     */
    ngxsOnInit({ dispatch }) {
        dispatch(StreamAuth);
    }
    /**
     * Listen to authentication state and updates AuthState accordingly.
     * @param dispatch new action
     */
    stream({ dispatch }) {
        return this.auth.user$.pipe(tap((user) => dispatch(new PatchStreamedAuth({ isInitialized: true, isLoggedIn: Boolean(user), user }))));
    }
    /**
     * Open a popup to sign in with Google Provider.
     */
    signInWithGoogle({ dispatch }, { isPopup }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                dispatch(StartLoading);
                yield this.auth.signInWithGoogle(isPopup);
                dispatch(RedirectAfterSignIn);
            }
            catch (e) {
                if (e.code !== 'auth/popup-closed-by-user') {
                    dispatch(new DisplayError(e));
                }
            }
            finally {
                dispatch(StopLoading);
            }
        });
    }
    /**
     * Redirect the user after they sign in
     */
    redirectUser({ dispatch }) {
        return __awaiter(this, void 0, void 0, function* () {
            this.auth.isAuthenticated().pipe(filter(Boolean), first(), tap(() => {
                // Get redirect url if exists after login
                const afterLogin = sessionStorage.getItem('afterLogin');
                const navigate = afterLogin ? JSON.parse(afterLogin) : { path: '/organizations' };
                // Make sure to be the last redirect
                setTimeout(() => dispatch(new Navigate([navigate.path], navigate.params)), 0);
                if (afterLogin) {
                    sessionStorage.removeItem('afterLogin');
                }
            })).subscribe();
        });
    }
    /**
     * Sign out the current authenticated user.
     */
    signOut() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.auth.signOut();
            return this.router.navigate(['/']);
        });
    }
    /**
     * Patch the current state.
     * Note - Since StreamAuth never completes, then Redux DevTools won't be able to report when it changes the state.
     * Therefore, instead of directly changing its state, it will call this action.
     * @param patchState of the current state
     * @param payload of the current state
     */
    patch({ patchState }, { payload }) {
        return patchState(payload);
    }
    /**
     * Redirect user to third party they came from for token exchange
     */
    redirectThirdParty({ dispatch }, { redirectUri, state }) {
        dispatch(new StartLoading());
        const redirect = new URL(redirectUri);
        redirect.searchParams.append('code', this.auth.uid);
        redirect.searchParams.append('state', state);
        location.href = redirect.href;
    }
};
AuthState.ɵfac = function AuthState_Factory(t) { return new (t || AuthState)(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router)); };
AuthState.ɵprov = i0.ɵɵdefineInjectable({ token: AuthState, factory: AuthState.ɵfac });
__decorate([
    Action(StreamAuth)
], AuthState.prototype, "stream", null);
__decorate([
    Action(SignInWithGoogle)
], AuthState.prototype, "signInWithGoogle", null);
__decorate([
    Action(RedirectAfterSignIn)
], AuthState.prototype, "redirectUser", null);
__decorate([
    Action(SignOut)
], AuthState.prototype, "signOut", null);
__decorate([
    Action(PatchStreamedAuth)
], AuthState.prototype, "patch", null);
__decorate([
    Action(RedirectThirdParty)
], AuthState.prototype, "redirectThirdParty", null);
AuthState = __decorate([
    State({
        name: 'auth',
        defaults: initialState
    })
], AuthState);
export { AuthState };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AuthState, [{
        type: Injectable
    }], function () { return [{ type: i1.AuthService }, { type: i2.Router }]; }, { stream: [], signInWithGoogle: [], redirectUser: [], signOut: [], patch: [], redirectThirdParty: [] }); })();
