import {Pipe, PipeTransform} from '@angular/core';
import * as firebase from 'firebase/app';

@Pipe({
  name: 'document'
})
export class DocumentPipe<T = any, R = any> implements PipeTransform {
  static deepTransform(doc: any, cache: Set<unknown>): any {
    if (!doc) {
      return doc;
    }

    if (doc instanceof firebase.firestore.Timestamp) {
      return doc.toDate();
    }

    if (Array.isArray(doc)) {
      return doc.map(av => DocumentPipe.deepTransform(av, cache));
    }

    if (doc instanceof Map) {
      return new Map(Array.from(doc).map(([k, v]) => ([k, DocumentPipe.deepTransform(v, cache)])))
    }

    if (typeof doc === 'object' && !doc.firestore && !cache.has(doc) && !(doc instanceof Date)) {
      cache.add(doc);

      const newDoc = {...doc};
      Object.keys(newDoc).forEach(k => {
        newDoc[k] = DocumentPipe.deepTransform(newDoc[k], cache);
      });

      return newDoc;
    }

    if(doc.firestore) {
      return null;
    }

    return doc;
  }

  transform(doc: T, ...args: any[]): R {
    return DocumentPipe.deepTransform(doc, new Set<unknown>());
  }
}
