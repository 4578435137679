<ng-container *transloco="let t; read: 'config.fields'" [formGroup]="form">
  <ng-container *ngFor="let field of fields" [ngSwitch]="field.type">
    <ng-container *ngSwitchCase="'INFO_LIST'">
      <p>{{t(field.name) || field.displayName }}</p>
      <ul>
        <ng-container *ngFor="let option of field.options">
          <li *ngIf="option.value"><a [href]="option.value" target="_blank">{{option.label}}</a></li>
          <li *ngIf="!option.value">{{option.label}}</li>
        </ng-container>
      </ul>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <mat-form-field *ngIf="isField(field)" [matTooltip]="t('helpText.' + field.name) || field.helpText"
                      [matTooltipPosition]="'right'" [matTooltipClass]="'explanation-tooltip'">
        <mat-label>{{t(field.name) || field.displayName }}</mat-label>
        <ng-container [ngSwitch]="field.type">
          <input matInput *ngSwitchCase="'TEXTINPUT'" [placeholder]="field.helpText"
                 [formControlName]="field.name"/>

          <mat-select *ngSwitchCase="'SELECT_SINGLE'" [formControlName]="field.name" [placeholder]="field.helpText">
            <mat-option *ngFor="let option of field.options"
                        [value]="option.value">{{option.label}}</mat-option>
          </mat-select>

          <mat-select *ngSwitchCase="'CHECKBOX'" [formControlName]="field.name" [placeholder]="field.helpText">
            <mat-option [value]="true">Yes</mat-option>
            <mat-option [value]="false">No</mat-option>
          </mat-select>

          <ng-container *ngSwitchDefault>
            TODO...
          </ng-container>
        </ng-container>
        <mat-error *ngIf="form.get(field.name).errors?.required">{{ 'forms.errors.required' | transloco }}</mat-error>
        <mat-error
          *ngIf="form.get(field.name).errors?.whitespace">{{ 'forms.errors.whitespace' | transloco }}</mat-error>
      </mat-form-field>
    </ng-container>
  </ng-container>
</ng-container>
