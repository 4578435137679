import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {

  links: Array<{ text: string, href: string }> = [
    {text: 'about', href: 'https://www.powermyanalytics.com/about/'},
    {text: 'affiliates', href: 'https://www.powermyanalytics.com/affiliates/'},
    {text: 'contact', href: 'https://www.powermyanalytics.com/contact/'},
    {text: 'support', href: 'https://support.powermyanalytics.com/portal/kb'},
    {text: 'privacy', href: 'https://www.powermyanalytics.com/privacy/'},
    {text: 'terms', href: 'https://www.powermyanalytics.com/terms/'},
    {text: 'updates', href: 'https://www.powermyanalytics.com/updates/'},
  ];

  socials: Array<{ icon: string, href: string }> = [
    {icon: 'linkedin-in', href: 'https://www.linkedin.com/company/power-my-analytics/'},
    {icon: 'twitter', href: 'https://twitter.com/ShowMeROI'},
    {icon: 'facebook-f', href: 'https://www.facebook.com/PowerMyAnalytics/'},
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
