import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {CollectionReference} from '@angular/fire/firestore/interfaces';
import {AuthService} from '@core/services/auth/auth.service';
import {NotificationPipe} from '@shared/pipes/notification/notification.pipe';
import * as firebase from 'firebase/app';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {WithId} from '../../../../../shared/interfaces/with-id';
import {NotificationAction, NotificationDocument, NotificationModel} from '../../../../../shared/types/notification';
import {UserModel} from '../../../../../shared/types/user';
import {Select} from '@ngxs/store';
import {orderBy} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  @Select(state => state.organizations ? state.organizations.selectedId : null) organizationId$: Observable<string>;

  /**
   * TODO docs
   */
  static readonly MAX_LIMIT: number = 50;

  /**
   * TODO docs
   */
  notifications$: Observable<NotificationModel[]> = combineLatest([this.auth.user$, this.organizationId$]).pipe(
    filter(([user, organizationId]) => Boolean(user)),
    switchMap(([user, organizationId]) => this.getNotifications(user, organizationId)),
  );

  constructor(private auth: AuthService,
              private afs: AngularFirestore,
              private notificationPipe: NotificationPipe) {
  }

  getNotifications(user: UserModel, organizationId: string) {
    const refs = this.getRelevantRefs(user, organizationId);


    return this.afs.collection<NotificationDocument>('notifications', (ref: CollectionReference) => ref
      .where('to', 'array-contains-any', refs)
      .limit(NotificationsService.MAX_LIMIT))
      .valueChanges({idField: 'id'})
      .pipe(
        map(collection => this.transformCollection(collection)),
        map(models => orderBy(models, ['updatedAt'], ['desc']))
      );
  }

  /**
   * TODO docs
   * @param notification NotificationModel
   * @param action NotificationAction
   */
  patchStatus(notification: NotificationModel, action: NotificationAction) {
    return this.afs.collection('notifications').doc(notification.id).update({
      status: action,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  /**
   * TODO docs
   */
  markAsRead(notifications: NotificationModel[]) {
    const promises = notifications
      .filter(notification => !notification.hasRead)
      .map(notification => this.afs
        .collection<NotificationDocument>('notifications')
        .doc(notification.id)
        .update({hasRead: true}));

    return Promise.all(promises);
  }

  private getRelevantRefs(user: UserModel, organizationId: string) {
    const refs = [this.afs.doc(`users/${user.uid}`).ref];
    if (organizationId) {
      refs.push(this.afs.doc(`organizations/${organizationId}`).ref);
    }
    return refs;
  }

  /**
   * TODO docs
   * @param collection WithId<NotificationDocument>[]
   */
  private transformCollection(collection: WithId<NotificationDocument>[]) {
    return collection.map(doc => this.notificationPipe.transform(doc))
  }
}
