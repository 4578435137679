import { __decorate } from "tslib";
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/material/toolbar";
import * as i3 from "@angular/router";
import * as i4 from "./static-nav-items/static-nav-items.component";
import * as i5 from "./auth-nav-items/auth-nav-items.component";
const _c0 = function () { return ["/"]; };
export class NavComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
        //
    }
}
NavComponent.ɵfac = function NavComponent_Factory(t) { return new (t || NavComponent)(i0.ɵɵdirectiveInject(i1.Store)); };
NavComponent.ɵcmp = i0.ɵɵdefineComponent({ type: NavComponent, selectors: [["app-nav"]], decls: 7, vars: 2, consts: [[1, "container"], [1, "edge"], ["src", "assets/images/logo-orange.png", "alt", "Logo", "id", "logo", 3, "routerLink"], [1, "nav"]], template: function NavComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "mat-toolbar");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵelement(3, "img", 2);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "ul", 3);
        i0.ɵɵelement(5, "app-static-nav-items");
        i0.ɵɵelement(6, "app-auth-nav-items");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(3);
        i0.ɵɵproperty("routerLink", i0.ɵɵpureFunction0(1, _c0));
    } }, directives: [i2.MatToolbar, i3.RouterLink, i4.StaticNavItemsComponent, i5.AuthNavItemsComponent], styles: ["[_nghost-%COMP%]{z-index:10;position:relative}mat-toolbar[_ngcontent-%COMP%]{background-color:rgba(0,0,0,.87);-webkit-backdrop-filter:blur(16px);backdrop-filter:blur(16px);position:fixed}.container[_ngcontent-%COMP%]{-webkit-box-pack:justify;justify-content:space-between;-webkit-box-align:center;align-items:center;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-direction:row}img#logo[_ngcontent-%COMP%]{cursor:pointer;display:block;width:107px}ul[_ngcontent-%COMP%]{display:-webkit-box;display:flex;list-style:none;padding:0;margin:0}ul[_ngcontent-%COMP%] > *[_ngcontent-%COMP%]{display:-webkit-box;display:flex}ul[_ngcontent-%COMP%] > *[_ngcontent-%COMP%] + *[_ngcontent-%COMP%]{-webkit-margin-start:24px;margin-inline-start:24px}"], changeDetection: 0 });
__decorate([
    Select(state => state.auth)
], NavComponent.prototype, "authState$", void 0);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NavComponent, [{
        type: Component,
        args: [{
                selector: 'app-nav',
                templateUrl: './nav.component.html',
                styleUrls: ['./nav.component.scss'],
                changeDetection: ChangeDetectionStrategy.OnPush,
            }]
    }], function () { return [{ type: i1.Store }]; }, { authState$: [] }); })();
