import { __decorate } from "tslib";
import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { SignInWithGoogle, SignOut } from '@store/auth/auth.actions';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@ngneat/transloco";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/material/menu";
import * as i7 from "./sign-in-with-google/sign-in-with-google.component";
import * as i8 from "../notifications/notifications.component";
import * as i9 from "@angular/router";
function AuthNavItemsComponent_ng_container_0_li_1_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "mat-spinner", 4);
    i0.ɵɵelementContainerEnd();
} }
function AuthNavItemsComponent_ng_container_0_li_1_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    const _r492 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "button", 5);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "mat-menu", 6, 7);
    i0.ɵɵelementStart(5, "app-sign-in-with-google", 8);
    i0.ɵɵlistener("click", function AuthNavItemsComponent_ng_container_0_li_1_ng_container_2_Template_app_sign_in_with_google_click_5_listener($event) { i0.ɵɵrestoreView(_r492); const ctx_r491 = i0.ɵɵnextContext(3); return ctx_r491.signInWithGoogle(); });
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const _r490 = i0.ɵɵreference(4);
    const t_r484 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("matMenuTriggerFor", _r490);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", t_r484("actions.signIn"), " ");
} }
function AuthNavItemsComponent_ng_container_0_li_1_ng_container_3_Template(rf, ctx) { if (rf & 1) {
    const _r495 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "app-notifications");
    i0.ɵɵelement(2, "img", 9);
    i0.ɵɵelementStart(3, "button", 10);
    i0.ɵɵlistener("click", function AuthNavItemsComponent_ng_container_0_li_1_ng_container_3_Template_button_click_3_listener($event) { i0.ɵɵrestoreView(_r495); const ctx_r494 = i0.ɵɵnextContext(3); return ctx_r494.signOut(); });
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const authState_r486 = i0.ɵɵnextContext().ngIf;
    const t_r484 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("src", authState_r486.user.photoURL, i0.ɵɵsanitizeUrl)("routerLink", "/organizations");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(t_r484("actions.signOut"));
} }
function AuthNavItemsComponent_ng_container_0_li_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 2);
    i0.ɵɵtemplate(1, AuthNavItemsComponent_ng_container_0_li_1_ng_container_1_Template, 2, 0, "ng-container", 3);
    i0.ɵɵtemplate(2, AuthNavItemsComponent_ng_container_0_li_1_ng_container_2_Template, 6, 2, "ng-container", 3);
    i0.ɵɵtemplate(3, AuthNavItemsComponent_ng_container_0_li_1_ng_container_3_Template, 5, 3, "ng-container", 3);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const authState_r486 = ctx.ngIf;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", authState_r486.isLoggedIn === undefined);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", authState_r486.isLoggedIn === false);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", authState_r486.isLoggedIn);
} }
function AuthNavItemsComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, AuthNavItemsComponent_ng_container_0_li_1_Template, 4, 3, "li", 1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r483 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 1, ctx_r483.authState$));
} }
export class AuthNavItemsComponent {
    constructor(store) {
        this.store = store;
    }
    ngOnInit() {
    }
    signInWithGoogle() {
        this.store.dispatch(new SignInWithGoogle(true));
    }
    signOut() {
        this.store.dispatch(SignOut);
    }
}
AuthNavItemsComponent.ɵfac = function AuthNavItemsComponent_Factory(t) { return new (t || AuthNavItemsComponent)(i0.ɵɵdirectiveInject(i1.Store)); };
AuthNavItemsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AuthNavItemsComponent, selectors: [["app-auth-nav-items"]], decls: 1, vars: 1, consts: [[4, "transloco", "translocoRead"], ["class", "auth-menu", 4, "ngIf"], [1, "auth-menu"], [4, "ngIf"], ["diameter", "32"], ["mat-raised-button", "", "color", "primary", 1, "auth-button", 3, "matMenuTriggerFor"], ["xPosition", "before", 1, "guest-menu"], ["guestMenu", "matMenu"], [3, "click"], ["alt", "avatar", 1, "auth-avatar", 3, "src", "routerLink"], ["mat-button", "", 1, "auth-button", 3, "click"]], template: function AuthNavItemsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, AuthNavItemsComponent_ng_container_0_Template, 3, 3, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("translocoRead", "app.nav");
    } }, directives: [i2.TranslocoDirective, i3.NgIf, i4.MatSpinner, i5.MatButton, i6.MatMenuTrigger, i6._MatMenu, i7.SignInWithGoogleComponent, i8.NotificationsComponent, i9.RouterLink], pipes: [i3.AsyncPipe], styles: ["li[_ngcontent-%COMP%]{color:rgba(255,255,255,.87);font-family:\"Product Sans\",Roboto,sans-serif;font-size:16px;font-weight:500;padding:0 12px;margin:0}li[_ngcontent-%COMP%] > *[_ngcontent-%COMP%] + *[_ngcontent-%COMP%]{-webkit-margin-start:8px;margin-inline-start:8px}li[_ngcontent-%COMP%]:last-child{-webkit-margin-end:-12px;margin-inline-end:-12px}li[_ngcontent-%COMP%] > a[_ngcontent-%COMP%], li[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]{color:rgba(255,255,255,.87);cursor:pointer;text-decoration:none}li[_ngcontent-%COMP%] > a[_ngcontent-%COMP%]:hover, li[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:hover{color:#fff}li[_ngcontent-%COMP%] > fa-icon[_ngcontent-%COMP%]{font-size:12px}.auth-menu[_ngcontent-%COMP%]{display:-webkit-box;display:flex}.auth-menu[_ngcontent-%COMP%] > *[_ngcontent-%COMP%] + *[_ngcontent-%COMP%]{-webkit-margin-start:16px;margin-inline-start:16px}.auth-button[_ngcontent-%COMP%]{box-shadow:0 0 0 3px rgba(255,152,0,.24)}.auth-avatar[_ngcontent-%COMP%]{border-radius:100%;border:3px solid #fff;box-sizing:border-box;cursor:pointer;width:36px;height:36px;overflow:hidden}.auth-avatar[_ngcontent-%COMP%]   [_ngcontent-%COMP%]:focus{outline:0}"] });
__decorate([
    Select(state => state.auth)
], AuthNavItemsComponent.prototype, "authState$", void 0);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AuthNavItemsComponent, [{
        type: Component,
        args: [{
                selector: 'app-auth-nav-items',
                templateUrl: './auth-nav-items.component.html',
                styleUrls: ['./auth-nav-items.component.scss']
            }]
    }], function () { return [{ type: i1.Store }]; }, { authState$: [] }); })();
