import { Pipe } from '@angular/core';
import { DocumentPipe } from '@shared/pipes/document/document.pipe';
import * as i0 from "@angular/core";
export class ConnectorPipe extends DocumentPipe {
    transform(doc, ...args) {
        const transformed = super.transform(doc);
        transformed.logoPath = `assets/images/providers/${doc.logoName}`;
        return transformed;
    }
}
ConnectorPipe.ɵfac = function ConnectorPipe_Factory(t) { return ɵConnectorPipe_BaseFactory(t || ConnectorPipe); };
ConnectorPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "connectorsProvider", type: ConnectorPipe, pure: true });
const ɵConnectorPipe_BaseFactory = i0.ɵɵgetInheritedFactory(ConnectorPipe);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ConnectorPipe, [{
        type: Pipe,
        args: [{
                name: 'connectorsProvider'
            }]
    }], null, null); })();
