import { __awaiter, __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { Action, Selector, State } from '@ngxs/store';
import { MarkNotificationsAsRead, PatchNotificationStatus, PatchStreamedNotifications, StreamNotifications } from '@store/notifications/notifications.actions';
import { tap } from 'rxjs/operators';
import { DisplayError } from '@store/app/app.actions';
import * as i0 from "@angular/core";
import * as i1 from "@core/services/notifications/notifications.service";
const initialState = {
    isInitialized: false,
    collection: undefined,
    unreadCount: 0,
};
let NotificationsState = class NotificationsState {
    constructor(service) {
        this.service = service;
        //
    }
    /**
     * TODO docs
     * @param state
     */
    static count(state) {
        return state.collection ? state.collection.length : undefined;
    }
    /**
     * Count the total unread messages
     * @param state
     */
    static countUnread(state) {
        return state.unreadCount;
    }
    /**
     * TODO docs
     * @param dispatch new action
     */
    ngxsOnInit({ dispatch }) {
        dispatch(StreamNotifications);
    }
    /**
     * TODO docs
     */
    stream({ dispatch }) {
        return this.service.notifications$.pipe(tap(notifications => dispatch(new PatchStreamedNotifications(notifications))));
    }
    /**
     * TODO docs
     */
    markAllAsRead({ getState, dispatch }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.service.markAsRead(getState().collection);
            }
            catch (e) {
                dispatch(new DisplayError(e));
            }
        });
    }
    ;
    /**
     * TODO docs
     */
    patchStatus({ dispatch }, { notification, action }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.service.patchStatus(notification, action);
            }
            catch (e) {
                dispatch(new DisplayError(e));
            }
        });
    }
    /**
     * TODO docs
     * @param patchState of the current state
     * @param notifications from the stream
     */
    patchStreamed({ patchState }, { notifications }) {
        patchState({
            isInitialized: true,
            collection: notifications,
            unreadCount: notifications.filter(notification => !notification.hasRead).length
        });
    }
};
NotificationsState.ɵfac = function NotificationsState_Factory(t) { return new (t || NotificationsState)(i0.ɵɵinject(i1.NotificationsService)); };
NotificationsState.ɵprov = i0.ɵɵdefineInjectable({ token: NotificationsState, factory: NotificationsState.ɵfac });
__decorate([
    Action(StreamNotifications)
], NotificationsState.prototype, "stream", null);
__decorate([
    Action(MarkNotificationsAsRead)
], NotificationsState.prototype, "markAllAsRead", null);
__decorate([
    Action(PatchNotificationStatus)
], NotificationsState.prototype, "patchStatus", null);
__decorate([
    Action(PatchStreamedNotifications)
], NotificationsState.prototype, "patchStreamed", null);
__decorate([
    Selector()
], NotificationsState, "count", null);
__decorate([
    Selector()
], NotificationsState, "countUnread", null);
NotificationsState = __decorate([
    State({
        name: 'notifications',
        defaults: initialState,
    })
], NotificationsState);
export { NotificationsState };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NotificationsState, [{
        type: Injectable
    }], function () { return [{ type: i1.NotificationsService }]; }, { stream: [], markAllAsRead: [], patchStatus: [], patchStreamed: [] }); })();
