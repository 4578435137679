import { Component, Input, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import * as i0 from "@angular/core";
import * as i1 from "@ngneat/transloco";
import * as i2 from "@angular/material/table";
import * as i3 from "@angular/material/sort";
import * as i4 from "@angular/material/paginator";
import * as i5 from "@angular/common";
function LogsTableComponent_div_0_th_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "th", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const t_r558 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(t_r558("type"));
} }
function LogsTableComponent_div_0_td_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "td", 13);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const log_r566 = ctx.$implicit;
    const t_r558 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(t_r558(log_r566.type, log_r566.params));
} }
function LogsTableComponent_div_0_th_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "th", 14);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const t_r558 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(t_r558("createdAt"));
} }
function LogsTableComponent_div_0_td_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "td", 15);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "date");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const log_r569 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind2(2, 1, log_r569.createdAt, "medium"));
} }
function LogsTableComponent_div_0_tr_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "tr", 16);
} }
function LogsTableComponent_div_0_tr_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "tr", 17);
} }
const _c0 = function () { return [5, 10, 25, 100]; };
function LogsTableComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵelementStart(1, "table", 2);
    i0.ɵɵelementContainerStart(2, 3);
    i0.ɵɵtemplate(3, LogsTableComponent_div_0_th_3_Template, 2, 1, "th", 4);
    i0.ɵɵtemplate(4, LogsTableComponent_div_0_td_4_Template, 2, 1, "td", 5);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementContainerStart(5, 6);
    i0.ɵɵtemplate(6, LogsTableComponent_div_0_th_6_Template, 2, 1, "th", 7);
    i0.ɵɵtemplate(7, LogsTableComponent_div_0_td_7_Template, 3, 4, "td", 8);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵtemplate(8, LogsTableComponent_div_0_tr_8_Template, 1, 0, "tr", 9);
    i0.ɵɵtemplate(9, LogsTableComponent_div_0_tr_9_Template, 1, 0, "tr", 10);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(10, "mat-paginator", 11);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const ctx_r557 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("dataSource", ctx_r557.dataSource);
    i0.ɵɵadvance(7);
    i0.ɵɵproperty("matHeaderRowDef", ctx_r557.displayedColumns)("matHeaderRowDefSticky", true);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("matRowDefColumns", ctx_r557.displayedColumns);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("pageSizeOptions", i0.ɵɵpureFunction0(5, _c0));
} }
export class LogsTableComponent {
    constructor() {
        this.displayedColumns = ['type', 'createdAt'];
    }
    ngAfterViewInit() {
        this.dataSource = new MatTableDataSource(this.logs);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
}
LogsTableComponent.ɵfac = function LogsTableComponent_Factory(t) { return new (t || LogsTableComponent)(); };
LogsTableComponent.ɵcmp = i0.ɵɵdefineComponent({ type: LogsTableComponent, selectors: [["app-logs-table"]], viewQuery: function LogsTableComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵviewQuery(MatPaginator, true);
        i0.ɵɵviewQuery(MatSort, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.paginator = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sort = _t.first);
    } }, inputs: { logs: "logs" }, decls: 1, vars: 1, consts: [["id", "logs-table", 4, "transloco", "translocoRead"], ["id", "logs-table"], ["mat-table", "", "matSort", "", 3, "dataSource"], ["matColumnDef", "type"], ["mat-header-cell", "", 4, "matHeaderCellDef"], ["mat-cell", "", 4, "matCellDef"], ["matColumnDef", "createdAt"], ["mat-header-cell", "", "mat-sort-header", "", 4, "matHeaderCellDef"], ["mat-cell", "", "class", "date-column", 4, "matCellDef"], ["mat-header-row", "", "id", "table-columns", 4, "matHeaderRowDef", "matHeaderRowDefSticky"], ["mat-row", "", 4, "matRowDef", "matRowDefColumns"], [3, "pageSizeOptions"], ["mat-header-cell", ""], ["mat-cell", ""], ["mat-header-cell", "", "mat-sort-header", ""], ["mat-cell", "", 1, "date-column"], ["mat-header-row", "", "id", "table-columns"], ["mat-row", ""]], template: function LogsTableComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, LogsTableComponent_div_0_Template, 11, 6, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("translocoRead", "app.logsDialog.logsTable");
    } }, directives: [i1.TranslocoDirective, i2.MatTable, i3.MatSort, i2.MatColumnDef, i2.MatHeaderCellDef, i2.MatCellDef, i2.MatHeaderRowDef, i2.MatRowDef, i4.MatPaginator, i2.MatHeaderCell, i2.MatCell, i3.MatSortHeader, i2.MatHeaderRow, i2.MatRow], pipes: [i5.DatePipe], styles: ["[_nghost-%COMP%]{width:100%}  .logs-dialog .mat-dialog-container{padding:0}td[_ngcontent-%COMP%]{padding:10px 24px!important}.date-column[_ngcontent-%COMP%]{min-width:165px}#logs-table[_ngcontent-%COMP%]{max-height:95vh}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LogsTableComponent, [{
        type: Component,
        args: [{
                selector: 'app-logs-table',
                templateUrl: './logs-table.component.html',
                styleUrls: ['./logs-table.component.scss']
            }]
    }], null, { paginator: [{
            type: ViewChild,
            args: [MatPaginator, { static: false }]
        }], sort: [{
            type: ViewChild,
            args: [MatSort, { static: false }]
        }], logs: [{
            type: Input
        }] }); })();
