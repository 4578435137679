import {AfterViewInit, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslocoService} from '@ngneat/transloco';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {BaseComponent} from '@shared/components/base/base.component';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent extends BaseComponent implements AfterViewInit, OnInit, OnDestroy {
  @HostBinding('style.background')
  @Input() color: string = null;

  private siteTitle = 'Power My Analytics';

  constructor(private titleService: Title, private elRef: ElementRef, private translocoService: TranslocoService) {
    super();
  }

  ngOnInit() {
    this.translocoService.events$.pipe(
      filter(e => e.type === 'translationLoadSuccess'),
      tap(() => setTimeout(this.setTitle.bind(this), 0)), // Wait for one change detection and update the title
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

  ngAfterViewInit() {
    this.setTitle();
  }

  setTitle() {
    const content = this.elRef.nativeElement.innerText.trim();
    if (content.length > 0) {
      this.titleService.setTitle(this.siteTitle + ' - ' + content);
    }
  }

  ngOnDestroy() {
    this.titleService.setTitle(this.siteTitle)
  }
}
