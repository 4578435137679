import { Component, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@shared/components/base/base.component';
import { Subject } from 'rxjs';
import { filter, takeUntil, takeWhile, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngneat/transloco";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/common";
import * as i5 from "@angular/material/button";
function ConfirmDialogComponent_div_0_ng_container_7_button_1_Template(rf, ctx) { if (rf & 1) {
    const _r63 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵlistener("click", function ConfirmDialogComponent_div_0_ng_container_7_button_1_Template_button_click_0_listener($event) { i0.ɵɵrestoreView(_r63); const action_r58 = i0.ɵɵnextContext().$implicit; return action_r58.callback ? action_r58.callback() : null; });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const action_r58 = i0.ɵɵnextContext().$implicit;
    const t_r56 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(t_r56("actions." + action_r58.value));
} }
function ConfirmDialogComponent_div_0_ng_container_7_button_2_Template(rf, ctx) { if (rf & 1) {
    const _r68 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 10);
    i0.ɵɵlistener("click", function ConfirmDialogComponent_div_0_ng_container_7_button_2_Template_button_click_0_listener($event) { i0.ɵɵrestoreView(_r68); const action_r58 = i0.ɵɵnextContext().$implicit; return action_r58.callback ? action_r58.callback() : null; });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const action_r58 = i0.ɵɵnextContext().$implicit;
    const t_r56 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(t_r56("actions." + action_r58.value));
} }
function ConfirmDialogComponent_div_0_ng_container_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ConfirmDialogComponent_div_0_ng_container_7_button_1_Template, 2, 1, "button", 7);
    i0.ɵɵtemplate(2, ConfirmDialogComponent_div_0_ng_container_7_button_2_Template, 2, 1, "button", 8);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const action_r58 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", action_r58.primary);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !action_r58.primary);
} }
function ConfirmDialogComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3);
    i0.ɵɵelementStart(1, "h2", 4);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "mat-dialog-content");
    i0.ɵɵelementStart(4, "p");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "mat-dialog-actions", 5);
    i0.ɵɵtemplate(7, ConfirmDialogComponent_div_0_ng_container_7_Template, 3, 2, "ng-container", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const t_r56 = ctx.$implicit;
    const ctx_r55 = i0.ɵɵnextContext();
    i0.ɵɵproperty("hidden", ctx_r55.isLoading);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r55.title || t_r56("title"));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r55.content || t_r56("content"));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", ctx_r55.actions);
} }
export class ConfirmDialogComponent extends BaseComponent {
    constructor(dialogRef, data) {
        super();
        this.dialogRef = dialogRef;
        this.data = data;
        this.isLoading = false;
        this.closeOnFalseyAction = true;
        this.actions = [
            {
                primary: false,
                value: 'cancel',
                callback: () => this.action.next(false)
            },
            {
                primary: true,
                value: 'confirm',
                callback: () => this.action.next(true)
            }
        ];
        this.action = new Subject();
        this.title = (data && data.title) || this.title;
        this.content = (data && data.content) || this.content;
        this.actions = (data && data.actions) || this.actions;
    }
    ngOnInit() {
        this.action.pipe(takeWhile(() => this.closeOnFalseyAction), filter(value => !value), tap(() => this.dialogRef.close()), takeUntil(this.ngUnsubscribe)).subscribe();
    }
}
ConfirmDialogComponent.ɵfac = function ConfirmDialogComponent_Factory(t) { return new (t || ConfirmDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA)); };
ConfirmDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ConfirmDialogComponent, selectors: [["app-confirm-dialog"]], inputs: { isLoading: "isLoading", closeOnFalseyAction: "closeOnFalseyAction", title: "title", content: "content", actions: "actions" }, outputs: { action: "action" }, features: [i0.ɵɵInheritDefinitionFeature], decls: 3, vars: 2, consts: [[3, "hidden", 4, "transloco", "translocoRead"], [1, "loading", 3, "hidden"], ["mode", "indeterminate"], [3, "hidden"], ["mat-dialog-title", ""], [1, "align-end"], [4, "ngFor", "ngForOf"], ["mat-flat-button", "", "color", "warn", 3, "click", 4, "ngIf"], ["mat-stroked-button", "", 3, "click", 4, "ngIf"], ["mat-flat-button", "", "color", "warn", 3, "click"], ["mat-stroked-button", "", 3, "click"]], template: function ConfirmDialogComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, ConfirmDialogComponent_div_0_Template, 8, 4, "div", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelement(2, "mat-progress-bar", 2);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("translocoRead", "app.dialogs.confirm");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("hidden", !ctx.isLoading);
    } }, directives: [i2.TranslocoDirective, i3.MatProgressBar, i1.MatDialogTitle, i1.MatDialogContent, i1.MatDialogActions, i4.NgForOf, i4.NgIf, i5.MatButton], styles: [".loading[_ngcontent-%COMP%]{width:320px;max-width:100%}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ConfirmDialogComponent, [{
        type: Component,
        args: [{
                selector: 'app-confirm-dialog',
                templateUrl: './confirm-dialog.component.html',
                styleUrls: ['./confirm-dialog.component.scss']
            }]
    }], function () { return [{ type: i1.MatDialogRef }, { type: undefined, decorators: [{
                type: Inject,
                args: [MAT_DIALOG_DATA]
            }] }]; }, { isLoading: [{
            type: Input
        }], closeOnFalseyAction: [{
            type: Input
        }], title: [{
            type: Input
        }], content: [{
            type: Input
        }], actions: [{
            type: Input
        }], action: [{
            type: Output
        }] }); })();
