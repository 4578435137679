import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {SignInWithGoogle, SignOut} from '@store/auth/auth.actions';
import {AuthStateModel} from '@store/auth/auth.state';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-auth-nav-items',
  templateUrl: './auth-nav-items.component.html',
  styleUrls: ['./auth-nav-items.component.scss']
})
export class AuthNavItemsComponent implements OnInit {

  @Select(state => state.auth) authState$: Observable<AuthStateModel>;

  constructor(private store: Store) {
  }

  ngOnInit() {
  }

  signInWithGoogle() {
    this.store.dispatch(new SignInWithGoogle(true));
  }

  signOut() {
    this.store.dispatch(SignOut);
  }
}
