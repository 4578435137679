import {Component, Input} from '@angular/core';
import {PublicUserData} from '../../../../../shared/types/user';

@Component({
  selector: 'app-table-user-cell',
  templateUrl: './table-user-cell.component.html',
  styleUrls: ['./table-user-cell.component.scss']
})
export class TableUserCellComponent {

  @Input() userData: PublicUserData;

  defaultPhotoURL: string = 'assets/images/default-avatar.png';
}
