import { Pipe } from '@angular/core';
import { DocumentPipe } from '@shared/pipes/document/document.pipe';
import * as i0 from "@angular/core";
export class AccountPipe extends DocumentPipe {
    transform(doc, accountId) {
        delete doc.token;
        delete doc.organizations;
        return Object.assign(Object.assign({}, super.transform(doc)), { id: accountId });
    }
}
AccountPipe.ɵfac = function AccountPipe_Factory(t) { return ɵAccountPipe_BaseFactory(t || AccountPipe); };
AccountPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "account", type: AccountPipe, pure: true });
const ɵAccountPipe_BaseFactory = i0.ɵɵgetInheritedFactory(AccountPipe);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AccountPipe, [{
        type: Pipe,
        args: [{
                name: 'account'
            }]
    }], null, null); })();
