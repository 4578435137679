import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {NotificationAction, NotificationDocument, NotificationModel, NotificationType} from '../../../../../shared/types/notification';

@Pipe({
  name: 'notification'
})
export class NotificationPipe extends DocumentPipe<NotificationDocument, NotificationModel> implements PipeTransform {

  transform(doc: NotificationDocument): NotificationModel {
    if("organization" in doc) {
      delete doc.organization;
    }
    if("user" in doc) {
      delete doc.user;
    }
    delete doc.to;

    const transformed = super.transform(doc);

    transformed.actions = {
      [NotificationType.Invitation]: [
        {value: NotificationAction.Decline},
        {value: NotificationAction.Accept, primary: true},
      ],
    }[doc.type];

    return transformed;
  }
}
