import {Injectable} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserPipe} from '@shared/pipes/auth/user.pipe';
import {auth, User as FirebaseUser} from 'firebase/app';
import {Observable, of} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {UserDocument, UserModel} from '../../../../../shared/types/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private afAuth: AngularFireAuth,
              private userPipe: UserPipe,
              private afs: AngularFirestore,
              private analytics: AngularFireAnalytics) {
  }

  get uid(): string {
    return this.afAuth.auth.currentUser.uid;
  }

  /**
   * Get the current logged in authenticated user
   */
  get user$(): Observable<UserModel> {
    return this.afAuth.authState.pipe(
      switchMap((firebaseUser: FirebaseUser) => {
        return !firebaseUser
          ? of(null)
          : this.getDoc$(firebaseUser).pipe(
            filter(doc => Boolean(doc)),
            map(doc => this.mapToModel(doc, firebaseUser)),
            tap(user => this.trackUserAnalytics(user))
          );
      })
    );
  }

  isAuthenticated(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      map(firebaseUser => Boolean(firebaseUser))
    );
  }

  /**
   * Signs in the user via Google Provider
   */
  signInWithGoogle(isPopup: boolean): Promise<any> {
    const googleAuth = new auth.GoogleAuthProvider();

    if(isPopup) {
      return this.afAuth.auth.signInWithPopup(googleAuth);
    }

    return this.afAuth.auth.signInWithRedirect(googleAuth);
  }

  /**
   * Logs the user out
   */
  signOut(): Promise<void> {
    return this.afAuth.auth.signOut();
  }

  /**
   * Get the document of the current active user
   * @param firebaseUser
   */
  private getDoc$(firebaseUser: FirebaseUser): Observable<UserDocument> {
    return this.afs.doc<UserDocument>(`users/${firebaseUser.uid}`).valueChanges();
  }

  /**
   * Map `UserDocument` and `User` to `UserModel`
   * @param doc
   * @param firebaseUser
   */
  private mapToModel(doc: UserDocument, firebaseUser: FirebaseUser): UserModel {
    return this.userPipe.transform({firebaseUser, doc});
  }

  /**
   * Set UserId for analytics
   * @param user
   */
  private trackUserAnalytics(user: UserModel): Promise<void> {
    return this.analytics.setUserId(user.uid);
  }
}
