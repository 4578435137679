import {Component, Input, OnInit} from '@angular/core';
import {ConfigItem} from '../../../../../shared/types/connector';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-config-form',
  templateUrl: './config-form.component.html',
  styleUrls: ['./config-form.component.scss']
})
export class ConfigFormComponent implements OnInit {

  @Input() fields: ConfigItem[];
  @Input() form: FormGroup;
  @Input() integration: string = null;

  constructor() {
  }

  ngOnInit() {
    this.fields.filter(this.isField.bind(this)).forEach(field => {
      const defaultValue = field.type === 'CHECKBOX' ? field.default || false : null;
      this.form.registerControl(field.name, new FormControl(defaultValue, [Validators.required, this.noWhitespaceValidator]));
    });
  }

  isField(field) {
    return (!this.integration || !field.integrations || !(this.integration in field.integrations) || field.integrations[this.integration]) && field.type !== 'INFO_LIST';
  }

  public noWhitespaceValidator(control: FormControl) {
    if (typeof control.value !== 'string') {
      return null;
    }
    const value = control.value || '';
    const isWhitespace = value.trim().length !== value.length;
    return isWhitespace ? {'whitespace': true} : null;
  }

}
