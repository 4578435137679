import { Component } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngneat/transloco";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/menu";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@angular/router";
const _c0 = function () { return ["fal", "chevron-down"]; };
function StaticNavItemsComponent_ng_container_0_ng_container_1_li_1_fa_icon_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "fa-icon", 8);
} if (rf & 2) {
    i0.ɵɵproperty("icon", i0.ɵɵpureFunction0(1, _c0));
} }
function StaticNavItemsComponent_ng_container_0_ng_container_1_li_1_button_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "button", 9);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const child_r550 = ctx.$implicit;
    const item_r544 = i0.ɵɵnextContext(2).$implicit;
    const t_r542 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("routerLink", child_r550.href);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate1(" ", t_r542("menu." + item_r544.title + ".children." + child_r550.title), " ");
} }
function StaticNavItemsComponent_ng_container_0_ng_container_1_li_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 4);
    i0.ɵɵelementStart(1, "span");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, StaticNavItemsComponent_ng_container_0_ng_container_1_li_1_fa_icon_3_Template, 1, 2, "fa-icon", 5);
    i0.ɵɵelementStart(4, "mat-menu", null, 6);
    i0.ɵɵtemplate(6, StaticNavItemsComponent_ng_container_0_ng_container_1_li_1_button_6_Template, 2, 2, "button", 7);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const _r548 = i0.ɵɵreference(5);
    const item_r544 = i0.ɵɵnextContext().$implicit;
    const t_r542 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("matMenuTriggerFor", _r548);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(t_r542("menu." + item_r544.title + ".title"));
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", item_r544.children);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngForOf", item_r544.children);
} }
function StaticNavItemsComponent_ng_container_0_ng_container_1_li_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelementStart(1, "a", 10);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r544 = i0.ɵɵnextContext().$implicit;
    const t_r542 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("href", item_r544.href, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(t_r542("menu." + item_r544.title + ".title"));
} }
function StaticNavItemsComponent_ng_container_0_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, StaticNavItemsComponent_ng_container_0_ng_container_1_li_1_Template, 7, 4, "li", 2);
    i0.ɵɵtemplate(2, StaticNavItemsComponent_ng_container_0_ng_container_1_li_2_Template, 3, 2, "li", 3);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const item_r544 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", item_r544.children);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !item_r544.children);
} }
function StaticNavItemsComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, StaticNavItemsComponent_ng_container_0_ng_container_1_Template, 3, 2, "ng-container", 1);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r541 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r541.menu);
} }
export class StaticNavItemsComponent {
    constructor() {
        this.menu = [
            // {
            //   title: 'products',
            //   children: [
            //     {title: 'dataStudio', href: '/products/data-studio'},
            //     // {title: 'googleAnalytics', href: '/products/google-analytics'}
            //   ],
            // },
            {
                title: 'home',
                href: '/',
            },
            {
                title: 'plans',
                href: '/plans',
            },
            {
                title: 'support',
                href: 'https://support.powermyanalytics.com/portal/kb',
            }
        ];
    }
    ngOnInit() {
    }
}
StaticNavItemsComponent.ɵfac = function StaticNavItemsComponent_Factory(t) { return new (t || StaticNavItemsComponent)(); };
StaticNavItemsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: StaticNavItemsComponent, selectors: [["app-static-nav-items"]], decls: 1, vars: 1, consts: [[4, "transloco", "translocoRead"], [4, "ngFor", "ngForOf"], [3, "matMenuTriggerFor", 4, "ngIf"], [4, "ngIf"], [3, "matMenuTriggerFor"], [3, "icon", 4, "ngIf"], ["menu", "matMenu"], ["mat-menu-item", "", 3, "routerLink", 4, "ngFor", "ngForOf"], [3, "icon"], ["mat-menu-item", "", 3, "routerLink"], [3, "href"]], template: function StaticNavItemsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, StaticNavItemsComponent_ng_container_0_Template, 2, 1, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("translocoRead", "app.nav");
    } }, directives: [i1.TranslocoDirective, i2.NgForOf, i2.NgIf, i3.MatMenuTrigger, i3._MatMenu, i4.FaIconComponent, i3.MatMenuItem, i5.RouterLink], styles: ["li[_ngcontent-%COMP%]{color:rgba(255,255,255,.87);font-family:\"Product Sans\",Roboto,sans-serif;font-size:16px;font-weight:500;padding:0 12px;margin:0}li[_ngcontent-%COMP%] > *[_ngcontent-%COMP%] + *[_ngcontent-%COMP%]{-webkit-margin-start:8px;margin-inline-start:8px}li[_ngcontent-%COMP%]:last-child{-webkit-margin-end:-12px;margin-inline-end:-12px}li[_ngcontent-%COMP%] > a[_ngcontent-%COMP%], li[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]{color:rgba(255,255,255,.87);cursor:pointer;text-decoration:none}li[_ngcontent-%COMP%] > a[_ngcontent-%COMP%]:hover, li[_ngcontent-%COMP%] > span[_ngcontent-%COMP%]:hover{color:#fff}li[_ngcontent-%COMP%] > fa-icon[_ngcontent-%COMP%]{font-size:12px}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(StaticNavItemsComponent, [{
        type: Component,
        args: [{
                selector: 'app-static-nav-items',
                templateUrl: './static-nav-items.component.html',
                styleUrls: ['./static-nav-items.component.scss']
            }]
    }], function () { return []; }, null); })();
