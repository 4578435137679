import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faLinkedinIn} from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
import {faChevronDown as falChevronDown} from '@fortawesome/pro-light-svg-icons/faChevronDown';
import {faTimes} from '@fortawesome/pro-light-svg-icons/faTimes';
import {faSearch} from '@fortawesome/pro-regular-svg-icons/faSearch';
import {faCog} from '@fortawesome/pro-solid-svg-icons/faCog';
import {faEdit} from '@fortawesome/pro-solid-svg-icons/faEdit';
import {faInbox} from '@fortawesome/pro-solid-svg-icons/faInbox';
import {faPrintSearch} from '@fortawesome/pro-solid-svg-icons/faPrintSearch';
import {faSignOut} from '@fortawesome/pro-solid-svg-icons/faSignOut';
import {faTrash} from '@fortawesome/pro-solid-svg-icons/faTrash';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {TranslocoModule} from '@ngneat/transloco';
import {NgxsModule} from '@ngxs/store';
import {PromptDialogComponent} from '@shared/components/prompt-dialog/prompt-dialog.component';
import {ProviderCardComponent} from '@shared/components/provider-card/provider-card.component';
import {AccountPipe} from '@shared/pipes/account/account.pipe';
import {ConnectorPipe} from '@shared/pipes/connector/connector.pipe';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {LogPipe} from '@shared/pipes/logs/log.pipe';
import {OrganizationPipe} from '@shared/pipes/organization/organization.pipe';
import {LogsState} from '@store/logs/logs.state';
import {flatten} from 'lodash';
import {BaseComponent} from './components/base/base.component';
import {ConfigFormComponent} from './components/config-form/config-form.component';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {PageTitleComponent} from './components/page-title/page-title.component';
import {PictureComponent} from './components/picture/picture.component';
import {TableUserCellComponent} from './components/table-user-cell/table-user-cell.component';
import {UserPipe} from './pipes/auth/user.pipe';
import {ConnectorsProviderPipe} from './pipes/connectors-provider/connectors-provider.pipe';
import {NotificationPipe} from './pipes/notification/notification.pipe';
import {SignInWithGoogleComponent} from '../components/nav/auth-nav-items/sign-in-with-google/sign-in-with-google.component';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {IconComponent} from './components/icon/icon.component';
import {faDatabase} from '@fortawesome/pro-solid-svg-icons/faDatabase';
import {faTimesSquare} from '@fortawesome/pro-solid-svg-icons/faTimesSquare';
import {MatTooltipModule} from '@angular/material/tooltip';
import {faCloudUpload} from '@fortawesome/pro-solid-svg-icons/faCloudUpload';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

const sharedPipes = [
  UserPipe,
  DocumentPipe,
  OrganizationPipe,
  AccountPipe,
  NotificationPipe,
  ConnectorPipe,
  ConnectorsProviderPipe,
  LogPipe
];

const sharedComponents = [
  PictureComponent,
  TableUserCellComponent,
  ProviderCardComponent,
  BaseComponent,
  PageTitleComponent,
  ConfirmDialogComponent,
  PromptDialogComponent,
  ConfigFormComponent,
  SignInWithGoogleComponent,
  IconComponent
];

@NgModule({
  declarations: [
    ...sharedPipes,
    ...sharedComponents
  ],
  exports: [
    ...sharedPipes,
    ...sharedComponents,
    FontAwesomeModule,
    PageTitleComponent,
    ConfigFormComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    MatRippleModule,
    FontAwesomeModule,
    NgxsModule.forFeature([LogsState]),
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
  ],
  providers: [
    ...sharedPipes,
  ]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    const brandIcons = [faLinkedinIn, faTwitter, faFacebookF];
    const lightIcons = [falChevronDown, faTimes];
    const regularIcons = [faSearch];
    const solidIcons = [faInbox, faSignOut, faPrintSearch, faCog, faEdit, faTrash, faPlus, faCheckCircle, faInfoCircle,
      faDatabase, faTimesSquare, faCloudUpload];

    library.addIcons(...flatten([brandIcons, lightIcons, regularIcons, solidIcons]));
  }
}
