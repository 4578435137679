import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAnalyticsModule} from '@angular/fire/analytics';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFirePerformanceModule} from '@angular/fire/performance';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TokenInterceptor} from '@core/interceptors/token.interceptor';
import {ngxsConfig} from '@core/ngxs.config';
import {NgxsInterceptorPluginModule} from '@core/plugins/ngxs-interceptor/ngxs-interceptor.plugin';
import {environment} from '@environments/environment';
import {TRANSLOCO_CONFIG, TranslocoConfig, TranslocoModule} from '@ngneat/transloco';
import {TranslocoMessageFormatModule} from '@ngneat/transloco-messageformat';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import {NgxsRouterPluginModule} from '@ngxs/router-plugin';
import {NgxsModule} from '@ngxs/store';
import {AuthState} from '@store/auth/auth.state';
import {NotificationsState} from '@store/notifications/notifications.state';
import {customTranslocoMissingHandler, translocoLoader} from '../transloco.loader';
import {LogsState} from '@store/logs/logs.state';
import {LogsService} from '@core/services/logs/logs.service';
import {AppState} from '@store/app/app.state';
import {AngularFireStorageModule, StorageBucket} from '@angular/fire/storage';
import {AngularFireDatabaseModule} from '@angular/fire/database';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    NgxsModule.forRoot([AppState, AuthState, NotificationsState, LogsState], ngxsConfig),
    NgxsRouterPluginModule.forRoot(),
    NgxsInterceptorPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    TranslocoMessageFormatModule.init(),
  ],
  exports: [
    BrowserModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: TRANSLOCO_CONFIG,
      useValue: {
        availableLangs: ['en', 'es'],
        defaultLang: 'en',
        prodMode: environment.production,
      } as TranslocoConfig
    },
    customTranslocoMissingHandler,
    translocoLoader,
    LogsService,
    { provide: StorageBucket, useValue: environment.firebase.storageBucket }
  ],
})
export class CoreModule {
}
