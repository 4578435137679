import {Component, Inject, Input} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProviderDialogData} from '../../../organizations/organization/providers/components/provider-dialog/provider-dialog.component';

interface PromptDialogData {
  title: string;
  content: string;
}

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {

  title: string;
  content: string;

  constructor(private dialogRef: MatDialogRef<PromptDialogComponent>,
              @Inject(MAT_DIALOG_DATA) private data: PromptDialogData) {
    this.title = data.title;
    this.content = data.content;
  }

  close() {
    this.dialogRef.close()
  }

}
