import {AuthStateModel} from '@store/auth/auth.state';

export class StreamAuth {
  static readonly type = '[Auth] Stream Auth Data';
}

export class SignInWithGoogle {
  static readonly type = '[Auth] Sign In With Google';
  static readonly eventParams = ['isPopup'];

  constructor(public isPopup: boolean) {
  }
}

export class RedirectAfterSignIn {
  static readonly type = '[Auth] Redirect After Sign In';

  constructor() {
  }
}

export class UpdateAuthProfile {
  static readonly type = '[Auth] Update Profile';
}

export class SignOut {
  static readonly type = '[Auth] Sign Out';
}

export class PatchStreamedAuth {
  static readonly type = '[Auth] Patch State';

  constructor(public payload: Partial<AuthStateModel>) {
  }
}

export class RedirectThirdParty {
  static readonly type = '[Auth] Redirect to Third Party';

  constructor(public redirectUri: string, public state: string) {
  }
}
