import { __decorate } from "tslib";
import { Component } from '@angular/core';
import { Select } from '@ngxs/store';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@angular/common";
import * as i3 from "./logs-table/logs-table.component";
import * as i4 from "@angular/material/progress-bar";
function LogsDialogComponent_ng_container_0_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "app-logs-table", 2);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const logs_r473 = i0.ɵɵnextContext().ngIf;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("logs", logs_r473.collection);
} }
function LogsDialogComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, LogsDialogComponent_ng_container_0_ng_container_1_Template, 2, 1, "ng-container", 0);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const logs_r473 = ctx.ngIf;
    i0.ɵɵnextContext();
    const _r471 = i0.ɵɵreference(3);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", logs_r473.isInitialized)("ngIfElse", _r471);
} }
function LogsDialogComponent_ng_template_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-progress-bar", 3);
} }
export class LogsDialogComponent {
    constructor(dialogRef) {
        this.dialogRef = dialogRef;
    }
}
LogsDialogComponent.ɵfac = function LogsDialogComponent_Factory(t) { return new (t || LogsDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef)); };
LogsDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: LogsDialogComponent, selectors: [["app-logs-dialog"]], decls: 4, vars: 4, consts: [[4, "ngIf", "ngIfElse"], ["loadingLogs", ""], [3, "logs"], ["mode", "indeterminate"]], template: function LogsDialogComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, LogsDialogComponent_ng_container_0_Template, 2, 2, "ng-container", 0);
        i0.ɵɵpipe(1, "async");
        i0.ɵɵtemplate(2, LogsDialogComponent_ng_template_2_Template, 1, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
    } if (rf & 2) {
        const _r471 = i0.ɵɵreference(3);
        i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx.logs$))("ngIfElse", _r471);
    } }, directives: [i2.NgIf, i3.LogsTableComponent, i4.MatProgressBar], pipes: [i2.AsyncPipe], styles: [""] });
__decorate([
    Select(state => state.logs)
], LogsDialogComponent.prototype, "logs$", void 0);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LogsDialogComponent, [{
        type: Component,
        args: [{
                selector: 'app-logs-dialog',
                templateUrl: './logs-dialog.component.html',
                styleUrls: ['./logs-dialog.component.scss']
            }]
    }], function () { return [{ type: i1.MatDialogRef }]; }, { logs$: [] }); })();
