import { Component } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/picture/picture.component";
export class SignInWithGoogleComponent {
}
SignInWithGoogleComponent.ɵfac = function SignInWithGoogleComponent_Factory(t) { return new (t || SignInWithGoogleComponent)(); };
SignInWithGoogleComponent.ɵcmp = i0.ɵɵdefineComponent({ type: SignInWithGoogleComponent, selectors: [["app-sign-in-with-google"]], decls: 1, vars: 0, consts: [["srcset", "assets/images/sign-in-with-google", 1, "sign-in-google"]], template: function SignInWithGoogleComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "app-picture", 0);
    } }, directives: [i1.PictureComponent], styles: [".sign-in-google[_ngcontent-%COMP%]{cursor:pointer}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(SignInWithGoogleComponent, [{
        type: Component,
        args: [{
                selector: 'app-sign-in-with-google',
                templateUrl: './sign-in-with-google.component.html',
                styleUrls: ['./sign-in-with-google.component.scss']
            }]
    }], null, null); })();
