import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
export class TokenInterceptor {
    constructor(afAuth) {
        this.afAuth = afAuth;
    }
    intercept(request, next) {
        return this.afAuth.idToken.pipe(tap(token => {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }), switchMap(() => next.handle(request)));
    }
}
TokenInterceptor.ɵfac = function TokenInterceptor_Factory(t) { return new (t || TokenInterceptor)(i0.ɵɵinject(i1.AngularFireAuth)); };
TokenInterceptor.ɵprov = i0.ɵɵdefineInjectable({ token: TokenInterceptor, factory: TokenInterceptor.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TokenInterceptor, [{
        type: Injectable
    }], function () { return [{ type: i1.AngularFireAuth }]; }, null); })();
