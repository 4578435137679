<ng-container *ngIf="notificationsState$ | async as notificationsState">
  <button mat-icon-button class="notifications-icon" [matMenuTriggerFor]="notificationsMenu">
    <fa-icon [icon]="['fas', 'inbox']"></fa-icon>
    <span *ngIf="unreadCount$ | async as count" [hidden]="!count" class="badge">{{ count }}</span>
  </button>

  <mat-menu #notificationsMenu="matMenu" xPosition="before" class="notifications-panel">
    <ng-container *ngIf="notificationsState.isInitialized">
      <ng-container *transloco="let t; read: 'app.nav.auth.notifications'">

        <div id="wrapper" (click)="$event.stopPropagation()">
          <!-- Notifications title -->
          <div class="title" [class.new]="notificationsState.unreadCount">
            {{ t('title', {count: notificationsState.unreadCount}) }}
          </div>

          <!-- No Results -->
          <div *ngIf="!notificationsState.collection.length" class="padding-16">
            {{ t('noResults') }}
          </div>

          <!-- Notifications List -->
          <div id="notifications-list">

            <!-- Notification Item -->
            <div *ngFor="let notification of notificationsState.collection" class="notification-item"
                 [class.new]="!notification.hasRead">

              <!-- Notification type -->
              <div class="type">{{ notification.type | titlecase }}</div>

              <!-- Notification timestamp -->
              <div class="timestamp">{{ notification.createdAt | date }}</div>

              <!-- Notification content-->
              <div class="content">
                {{ t('contents.' + notification.type, flatten(notification)) }}
              </div>

              <!-- Notification actions -->
              <div *ngIf="notification.actions" class="actions">

                <!-- Notification status (when notification has already actioned) -->
                <span *ngIf="notification.status !== undefined" class="final-state">
                  {{ t('states.' + notification.type + '.' + notification.status) }}
                </span>

                <ng-container *ngIf="!notification.status">
                  <ng-container *ngFor="let action of notification.actions">

                    <!-- Button value -->
                    <ng-template #buttonTemplate>{{ t('actions.' + action.value) }}</ng-template>

                    <!-- Primary action button -->
                    <button *ngIf="action.primary" (click)="dispatch(notification, action.value)" mat-flat-button
                            color="primary">
                      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
                    </button>

                    <!-- Secondary action button -->
                    <button *ngIf="!action.primary" (click)="dispatch(notification, action.value)" mat-stroked-button>
                      <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
                    </button>

                  </ng-container>
                </ng-container>
              </div>
            </div>

          </div>
        </div>
      </ng-container>

      <!-- Loading indicator -->
      <div *ngIf="!notificationsState.isInitialized" class="padding-64 align-center">
        <mat-spinner diameter="32"></mat-spinner>
      </div>

    </ng-container>
  </mat-menu>
</ng-container>
