import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Select} from '@ngxs/store';
import {Observable} from 'rxjs';
import {ConnectorsStateModel} from '@store/connectors/connectors.state';
import {LogsStateModel} from '@store/logs/logs.state';
import {MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {PublicUserData} from '../../../../shared/types/user';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {BaseComponent} from '@shared/components/base/base.component';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {EntityLogModel} from '../../../../shared/types/logs';

@Component({
  selector: 'app-logs-dialog',
  templateUrl: './logs-dialog.component.html',
  styleUrls: ['./logs-dialog.component.scss']
})
export class LogsDialogComponent {
  @Select(state => state.logs) logs$: Observable<LogsStateModel>;



  constructor(private dialogRef: MatDialogRef<LogsDialogComponent>) {
  }
}
