<div *transloco="let t; read: 'app.dialogs.prompt'">
  <h2 mat-dialog-title>{{ t("titles." + title) }}</h2>

  <mat-dialog-content>
    <p>{{content}}</p>
  </mat-dialog-content>

  <mat-dialog-actions class="align-end">
    <ng-container>
      <button mat-stroked-button (click)="close()">{{ t('actions.ok') }}</button>
    </ng-container>
  </mat-dialog-actions>
</div>
