import {Injectable} from '@angular/core';
import {NotificationsService} from '@core/services/notifications/notifications.service';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {NgxsOnInit} from '@ngxs/store/src/symbols';
import {MarkNotificationsAsRead, PatchNotificationStatus, PatchStreamedNotifications, StreamNotifications} from '@store/notifications/notifications.actions';
import {tap} from 'rxjs/operators';
import {NotificationModel} from '../../../../../shared/types/notification';
import {DisplayError} from '@store/app/app.actions';

export interface NotificationsStateModel {
  isInitialized: boolean;
  collection: NotificationModel[];
  unreadCount: number;
}

const initialState: NotificationsStateModel = {
  isInitialized: false,
  collection: undefined,
  unreadCount: 0,
};

@Injectable()
@State({
  name: 'notifications',
  defaults: initialState,
})
export class NotificationsState implements NgxsOnInit {

  /**
   * TODO docs
   * @param state
   */
  @Selector()
  static count(state: NotificationsStateModel): number {
    return state.collection ? state.collection.length : undefined;
  }

  /**
   * Count the total unread messages
   * @param state
   */
  @Selector()
  static countUnread(state: NotificationsStateModel): number {
    return state.unreadCount;
  }

  constructor(private service: NotificationsService) {
    //
  }

  /**
   * TODO docs
   * @param dispatch new action
   */
  ngxsOnInit({dispatch}: StateContext<NotificationsStateModel>): void {
    dispatch(StreamNotifications);
  }

  /**
   * TODO docs
   */
  @Action(StreamNotifications)
  stream({dispatch}: StateContext<NotificationsStateModel>) {
    return this.service.notifications$.pipe(
      tap(notifications => dispatch(new PatchStreamedNotifications(notifications)))
    );
  }

  /**
   * TODO docs
   */
  @Action(MarkNotificationsAsRead)
  async markAllAsRead({getState, dispatch}: StateContext<NotificationsStateModel>) {
    try {
      return await this.service.markAsRead(getState().collection);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  };

  /**
   * TODO docs
   */
  @Action(PatchNotificationStatus)
  async patchStatus({dispatch}: StateContext<NotificationsStateModel>, {notification, action}: PatchNotificationStatus) {
    try {
      return await this.service.patchStatus(notification, action);
    } catch (e) {
      dispatch(new DisplayError(e));
    }
  }

  /**
   * TODO docs
   * @param patchState of the current state
   * @param notifications from the stream
   */
  @Action(PatchStreamedNotifications)
  patchStreamed({patchState}: StateContext<NotificationsStateModel>, {notifications}: PatchStreamedNotifications) {
    patchState({
      isInitialized: true,
      collection: notifications,
      unreadCount: notifications.filter(notification => !notification.hasRead).length
    });
  }

}
