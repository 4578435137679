import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatMenu} from '@angular/material/menu';
import {Select, Store} from '@ngxs/store';
import {BaseComponent} from '@shared/components/base/base.component';
import {MarkNotificationsAsRead, PatchNotificationStatus} from '@store/notifications/notifications.actions';
import {NotificationsState, NotificationsStateModel} from '@store/notifications/notifications.state';
import {Observable} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {NotificationAction, NotificationModel} from '../../../../../shared/types/notification';
import {Dictionary} from '../../../../../shared/interfaces/dictionary.interface';
import {flatten} from '../../../../../shared/utils/flatten/flatten';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent extends BaseComponent implements AfterViewInit {

  @Select(NotificationsState.countUnread) unreadCount$: Observable<number>;
  @Select(state => state.notifications) notificationsState$: Observable<NotificationsStateModel>;
  @ViewChild(MatMenu) menu;

  constructor(private store: Store) {
    super();
  }

  ngAfterViewInit(): void {
    this.onCloseMenu();
  }

  dispatch(notification: NotificationModel, action: NotificationAction) {
    this.store.dispatch(new PatchNotificationStatus(notification, action));
  }

  flatten(notification: NotificationModel): Dictionary {
    return flatten(notification);
  }

  private onCloseMenu() {
    this.menu.close.pipe(
      tap(() => this.store.dispatch(MarkNotificationsAsRead)),
      takeUntil(this.ngUnsubscribe)
    ).subscribe();
  }

}
