import {Injectable} from '@angular/core';
import {BaseService} from '@core/services/base.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {LogPipe} from '@shared/pipes/logs/log.pipe';
import {map} from 'rxjs/operators';
import {EntityLogDocument, EntityLogModel} from '../../../../../shared/types/logs';
import {Observable} from 'rxjs';
import {FirebaseFirestore} from '@angular/fire/firebase.app.module';

@Injectable({
  providedIn: 'root'
})
export class LogsService extends BaseService {
  constructor(private afs: AngularFirestore, private transformer: LogPipe) {
    super();
  }

  list(entityPaths: string[], types?: string[], after?: Date, limit: number=100): Observable<EntityLogModel[]> {
    const entityRefs = entityPaths.map(entityPath => this.afs.doc(entityPath).ref);
    return this.afs.collection('logs', ref => {
      let q: any = ref;
      if (entityRefs && entityRefs.length) {
        q = q.where('ref', 'in', entityRefs);
      }
      if (types && types.length) {
        q = q.where('type', 'in', types);
      }
      if (after) {
        q = q.where('createdAt', '>', after);
      }

      return q.orderBy('createdAt', 'desc').limit(limit);
    }).valueChanges().pipe(
      map((logs: EntityLogDocument[]) => logs.map(this.transformer.transform))
    )
  }
}
