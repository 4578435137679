import { Component, Input } from '@angular/core';
import * as i0 from "@angular/core";
export class TableUserCellComponent {
    constructor() {
        this.defaultPhotoURL = 'assets/images/default-avatar.png';
    }
}
TableUserCellComponent.ɵfac = function TableUserCellComponent_Factory(t) { return new (t || TableUserCellComponent)(); };
TableUserCellComponent.ɵcmp = i0.ɵɵdefineComponent({ type: TableUserCellComponent, selectors: [["app-table-user-cell"]], inputs: { userData: "userData" }, decls: 6, vars: 3, consts: [["alt", "avatar", 1, "user-photo", 3, "src"], [1, "user-data"], [1, "user-name"], [1, "user-email"]], template: function TableUserCellComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "img", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "strong", 2);
        i0.ɵɵtext(3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "span", 3);
        i0.ɵɵtext(5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("src", ctx.userData.photoURL || ctx.defaultPhotoURL, i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate(ctx.userData.displayName);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate(ctx.userData.email);
    } }, styles: ["[_nghost-%COMP%]{display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center;height:80px}[_nghost-%COMP%] > *[_ngcontent-%COMP%] + *[_ngcontent-%COMP%]{-webkit-margin-start:12px;margin-inline-start:12px}.user-data[_ngcontent-%COMP%]{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}.user-name[_ngcontent-%COMP%]{font-size:14px;font-weight:500}.user-email[_ngcontent-%COMP%]{font-size:12px;color:#9e9e9e}.user-photo[_ngcontent-%COMP%]{display:block;width:48px;border-radius:100%;overflow:hidden}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(TableUserCellComponent, [{
        type: Component,
        args: [{
                selector: 'app-table-user-cell',
                templateUrl: './table-user-cell.component.html',
                styleUrls: ['./table-user-cell.component.scss']
            }]
    }], null, { userData: [{
            type: Input
        }] }); })();
