import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "@ngneat/transloco";
import * as i3 from "@angular/material/button";
function PromptDialogComponent_div_0_Template(rf, ctx) { if (rf & 1) {
    const _r25 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelementStart(1, "h2", 1);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "mat-dialog-content");
    i0.ɵɵelementStart(4, "p");
    i0.ɵɵtext(5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(6, "mat-dialog-actions", 2);
    i0.ɵɵelementContainerStart(7);
    i0.ɵɵelementStart(8, "button", 3);
    i0.ɵɵlistener("click", function PromptDialogComponent_div_0_Template_button_click_8_listener($event) { i0.ɵɵrestoreView(_r25); const ctx_r24 = i0.ɵɵnextContext(); return ctx_r24.close(); });
    i0.ɵɵtext(9);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const t_r23 = ctx.$implicit;
    const ctx_r22 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(t_r23("titles." + ctx_r22.title));
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r22.content);
    i0.ɵɵadvance(4);
    i0.ɵɵtextInterpolate(t_r23("actions.ok"));
} }
export class PromptDialogComponent {
    constructor(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.title = data.title;
        this.content = data.content;
    }
    close() {
        this.dialogRef.close();
    }
}
PromptDialogComponent.ɵfac = function PromptDialogComponent_Factory(t) { return new (t || PromptDialogComponent)(i0.ɵɵdirectiveInject(i1.MatDialogRef), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA)); };
PromptDialogComponent.ɵcmp = i0.ɵɵdefineComponent({ type: PromptDialogComponent, selectors: [["app-prompt-dialog"]], decls: 1, vars: 1, consts: [[4, "transloco", "translocoRead"], ["mat-dialog-title", ""], [1, "align-end"], ["mat-stroked-button", "", 3, "click"]], template: function PromptDialogComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, PromptDialogComponent_div_0_Template, 10, 3, "div", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("translocoRead", "app.dialogs.prompt");
    } }, directives: [i2.TranslocoDirective, i1.MatDialogTitle, i1.MatDialogContent, i1.MatDialogActions, i3.MatButton], styles: [""] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(PromptDialogComponent, [{
        type: Component,
        args: [{
                selector: 'app-prompt-dialog',
                templateUrl: './prompt-dialog.component.html',
                styleUrls: ['./prompt-dialog.component.scss']
            }]
    }], function () { return [{ type: i1.MatDialogRef }, { type: undefined, decorators: [{
                type: Inject,
                args: [MAT_DIALOG_DATA]
            }] }]; }, null); })();
