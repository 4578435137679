import {Injectable} from '@angular/core';
import {Action, State, StateContext} from '@ngxs/store';
import {EntityLogModel} from '../../../../../shared/types/logs';
import {catchError, takeUntil, tap} from 'rxjs/operators';
import {ResetLogs, StreamLogs} from '@store/logs/logs.actions';
import {LogsService} from '@core/services/logs/logs.service';
import {Subject} from 'rxjs';
import {DisplayError} from '@store/app/app.actions';

export interface LogsStateModel {
  isInitialized: boolean;
  collection: EntityLogModel[];
}

const initialState: LogsStateModel = {
  isInitialized: false,
  collection: [],
};

@Injectable()
@State<LogsStateModel>({
  name: 'logs',
  defaults: initialState
})
export class LogsState {
  subscriber: Subject<void>;

  constructor(private service: LogsService) {
  }

  @Action(StreamLogs)
  stream({patchState, dispatch}: StateContext<LogsStateModel>, {entityPath}: StreamLogs) {
    this.subscriber = new Subject<void>();

    return this.service.list([entityPath]).pipe(
      tap(logs => patchState({
        isInitialized: true,
        collection: logs,
      })),
      catchError(e => dispatch(new DisplayError(e))),
      takeUntil(this.subscriber)
    );
  }

  @Action(ResetLogs)
  resetLogs({patchState}: StateContext<LogsStateModel>) {
    this.subscriber.next();
    this.subscriber.complete();

    patchState(initialState);
  }
}
