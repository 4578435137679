import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@ngneat/transloco";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/form-field";
import * as i5 from "@angular/material/tooltip";
import * as i6 from "@angular/material/input";
import * as i7 from "@angular/material/select";
import * as i8 from "@angular/material/core";
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_1_ng_container_4_li_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelementStart(1, "a", 8);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const option_r33 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("href", option_r33.value, i0.ɵɵsanitizeUrl);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(option_r33.label);
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_1_ng_container_4_li_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const option_r33 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(option_r33.label);
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_1_ng_container_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_1_ng_container_4_li_1_Template, 3, 2, "li", 7);
    i0.ɵɵtemplate(2, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_1_ng_container_4_li_2_Template, 2, 1, "li", 7);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const option_r33 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", option_r33.value);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", !option_r33.value);
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "p");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "ul");
    i0.ɵɵtemplate(4, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_1_ng_container_4_Template, 3, 2, "ng-container", 6);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const field_r29 = i0.ɵɵnextContext().$implicit;
    const t_r27 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(t_r27(field_r29.name) || field_r29.displayName);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngForOf", field_r29.options);
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_input_4_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "input", 13);
} if (rf & 2) {
    const field_r29 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("placeholder", field_r29.helpText)("formControlName", field_r29.name);
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_select_5_mat_option_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-option", 16);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const option_r49 = ctx.$implicit;
    i0.ɵɵproperty("value", option_r49.value);
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(option_r49.label);
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_select_5_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-select", 14);
    i0.ɵɵtemplate(1, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_select_5_mat_option_1_Template, 2, 2, "mat-option", 15);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const field_r29 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("formControlName", field_r29.name)("placeholder", field_r29.helpText);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", field_r29.options);
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_select_6_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-select", 14);
    i0.ɵɵelementStart(1, "mat-option", 16);
    i0.ɵɵtext(2, "Yes");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "mat-option", 16);
    i0.ɵɵtext(4, "No");
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const field_r29 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("formControlName", field_r29.name)("placeholder", field_r29.helpText);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("value", true);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("value", false);
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_ng_container_7_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1, " TODO... ");
    i0.ɵɵelementContainerEnd();
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_error_8_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "transloco");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "forms.errors.required"));
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_error_9_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-error");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "transloco");
    i0.ɵɵelementEnd();
} if (rf & 2) {
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, "forms.errors.whitespace"));
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-form-field", 10);
    i0.ɵɵelementStart(1, "mat-label");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerStart(3, 3);
    i0.ɵɵtemplate(4, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_input_4_Template, 1, 2, "input", 11);
    i0.ɵɵtemplate(5, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_select_5_Template, 2, 3, "mat-select", 12);
    i0.ɵɵtemplate(6, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_select_6_Template, 5, 4, "mat-select", 12);
    i0.ɵɵtemplate(7, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_ng_container_7_Template, 2, 0, "ng-container", 5);
    i0.ɵɵelementContainerEnd();
    i0.ɵɵtemplate(8, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_error_8_Template, 3, 3, "mat-error", 7);
    i0.ɵɵtemplate(9, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_mat_error_9_Template, 3, 3, "mat-error", 7);
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const field_r29 = i0.ɵɵnextContext(2).$implicit;
    const t_r27 = i0.ɵɵnextContext().$implicit;
    const ctx_r40 = i0.ɵɵnextContext();
    i0.ɵɵproperty("matTooltip", t_r27("helpText." + field_r29.name) || field_r29.helpText)("matTooltipPosition", "right")("matTooltipClass", "explanation-tooltip");
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(t_r27(field_r29.name) || field_r29.displayName);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngSwitch", field_r29.type);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngSwitchCase", "TEXTINPUT");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngSwitchCase", "SELECT_SINGLE");
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngSwitchCase", "CHECKBOX");
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", ctx_r40.form.get(field_r29.name).errors == null ? null : ctx_r40.form.get(field_r29.name).errors.required);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r40.form.get(field_r29.name).errors == null ? null : ctx_r40.form.get(field_r29.name).errors.whitespace);
} }
function ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_mat_form_field_1_Template, 10, 10, "mat-form-field", 9);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const field_r29 = i0.ɵɵnextContext().$implicit;
    const ctx_r31 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r31.isField(field_r29));
} }
function ConfigFormComponent_ng_container_0_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 3);
    i0.ɵɵtemplate(1, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_1_Template, 5, 2, "ng-container", 4);
    i0.ɵɵtemplate(2, ConfigFormComponent_ng_container_0_ng_container_1_ng_container_2_Template, 2, 1, "ng-container", 5);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const field_r29 = ctx.$implicit;
    i0.ɵɵproperty("ngSwitch", field_r29.type);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngSwitchCase", "INFO_LIST");
} }
function ConfigFormComponent_ng_container_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 1);
    i0.ɵɵtemplate(1, ConfigFormComponent_ng_container_0_ng_container_1_Template, 3, 2, "ng-container", 2);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r26 = i0.ɵɵnextContext();
    i0.ɵɵproperty("formGroup", ctx_r26.form);
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r26.fields);
} }
export class ConfigFormComponent {
    constructor() {
        this.integration = null;
    }
    ngOnInit() {
        this.fields.filter(this.isField.bind(this)).forEach(field => {
            const defaultValue = field.type === 'CHECKBOX' ? field.default || false : null;
            this.form.registerControl(field.name, new FormControl(defaultValue, [Validators.required, this.noWhitespaceValidator]));
        });
    }
    isField(field) {
        return (!this.integration || !field.integrations || !(this.integration in field.integrations) || field.integrations[this.integration]) && field.type !== 'INFO_LIST';
    }
    noWhitespaceValidator(control) {
        if (typeof control.value !== 'string') {
            return null;
        }
        const value = control.value || '';
        const isWhitespace = value.trim().length !== value.length;
        return isWhitespace ? { 'whitespace': true } : null;
    }
}
ConfigFormComponent.ɵfac = function ConfigFormComponent_Factory(t) { return new (t || ConfigFormComponent)(); };
ConfigFormComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ConfigFormComponent, selectors: [["app-config-form"]], inputs: { fields: "fields", form: "form", integration: "integration" }, decls: 1, vars: 1, consts: [[3, "formGroup", 4, "transloco", "translocoRead"], [3, "formGroup"], [3, "ngSwitch", 4, "ngFor", "ngForOf"], [3, "ngSwitch"], [4, "ngSwitchCase"], [4, "ngSwitchDefault"], [4, "ngFor", "ngForOf"], [4, "ngIf"], ["target", "_blank", 3, "href"], [3, "matTooltip", "matTooltipPosition", "matTooltipClass", 4, "ngIf"], [3, "matTooltip", "matTooltipPosition", "matTooltipClass"], ["matInput", "", 3, "placeholder", "formControlName", 4, "ngSwitchCase"], [3, "formControlName", "placeholder", 4, "ngSwitchCase"], ["matInput", "", 3, "placeholder", "formControlName"], [3, "formControlName", "placeholder"], [3, "value", 4, "ngFor", "ngForOf"], [3, "value"]], template: function ConfigFormComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, ConfigFormComponent_ng_container_0_Template, 2, 2, "ng-container", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("translocoRead", "config.fields");
    } }, directives: [i1.TranslocoDirective, i2.NgControlStatusGroup, i2.FormGroupDirective, i3.NgForOf, i3.NgSwitch, i3.NgSwitchCase, i3.NgSwitchDefault, i3.NgIf, i4.MatFormField, i5.MatTooltip, i4.MatLabel, i6.MatInput, i2.DefaultValueAccessor, i2.NgControlStatus, i2.FormControlName, i7.MatSelect, i8.MatOption, i4.MatError], pipes: [i1.TranslocoPipe], styles: ["mat-form-field[_ngcontent-%COMP%]{display:block}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ConfigFormComponent, [{
        type: Component,
        args: [{
                selector: 'app-config-form',
                templateUrl: './config-form.component.html',
                styleUrls: ['./config-form.component.scss']
            }]
    }], function () { return []; }, { fields: [{
            type: Input
        }], form: [{
            type: Input
        }], integration: [{
            type: Input
        }] }); })();
