import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { ngxsConfig } from '@core/ngxs.config';
import { NgxsInterceptorPluginModule } from '@core/plugins/ngxs-interceptor/ngxs-interceptor.plugin';
import { environment } from '@environments/environment';
import { TRANSLOCO_CONFIG, TranslocoModule } from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '@store/auth/auth.state';
import { NotificationsState } from '@store/notifications/notifications.state';
import { customTranslocoMissingHandler, translocoLoader } from '../transloco.loader';
import { LogsState } from '@store/logs/logs.state';
import { LogsService } from '@core/services/logs/logs.service';
import { AppState } from '@store/app/app.state';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire";
import * as i2 from "@ngxs/store";
import * as i3 from "@ngxs/router-plugin";
import * as i4 from "./plugins/ngxs-interceptor/ngxs-interceptor.plugin";
import * as i5 from "@ngxs/devtools-plugin";
import * as i6 from "@ngxs/form-plugin";
import * as i7 from "@ngneat/transloco-messageformat";
export class CoreModule {
}
CoreModule.ɵmod = i0.ɵɵdefineNgModule({ type: CoreModule });
CoreModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CoreModule_Factory(t) { return new (t || CoreModule)(); }, providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: TRANSLOCO_CONFIG,
            useValue: {
                availableLangs: ['en', 'es'],
                defaultLang: 'en',
                prodMode: environment.production,
            }
        },
        customTranslocoMissingHandler,
        translocoLoader,
        LogsService,
        { provide: StorageBucket, useValue: environment.firebase.storageBucket }
    ], imports: [[
            CommonModule,
            BrowserAnimationsModule,
            HttpClientModule,
            AngularFireModule.initializeApp(environment.firebase),
            AngularFireAuthModule,
            AngularFirestoreModule,
            AngularFirePerformanceModule,
            AngularFireAnalyticsModule,
            AngularFireDatabaseModule,
            AngularFireStorageModule,
            NgxsModule.forRoot([AppState, AuthState, NotificationsState, LogsState], ngxsConfig),
            NgxsRouterPluginModule.forRoot(),
            NgxsInterceptorPluginModule.forRoot(),
            NgxsReduxDevtoolsPluginModule.forRoot(),
            NgxsFormPluginModule.forRoot(),
            TranslocoMessageFormatModule.init(),
        ],
        BrowserModule,
        TranslocoModule] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreModule, { imports: [CommonModule,
        BrowserAnimationsModule,
        HttpClientModule, i1.AngularFireModule, AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFirePerformanceModule,
        AngularFireAnalyticsModule,
        AngularFireDatabaseModule,
        AngularFireStorageModule, i2.ɵl, i3.NgxsRouterPluginModule, i4.NgxsInterceptorPluginModule, i5.NgxsReduxDevtoolsPluginModule, i6.NgxsFormPluginModule, i7.TranslocoMessageFormatModule], exports: [BrowserModule,
        TranslocoModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CoreModule, [{
        type: NgModule,
        args: [{
                declarations: [],
                imports: [
                    CommonModule,
                    BrowserAnimationsModule,
                    HttpClientModule,
                    AngularFireModule.initializeApp(environment.firebase),
                    AngularFireAuthModule,
                    AngularFirestoreModule,
                    AngularFirePerformanceModule,
                    AngularFireAnalyticsModule,
                    AngularFireDatabaseModule,
                    AngularFireStorageModule,
                    NgxsModule.forRoot([AppState, AuthState, NotificationsState, LogsState], ngxsConfig),
                    NgxsRouterPluginModule.forRoot(),
                    NgxsInterceptorPluginModule.forRoot(),
                    NgxsReduxDevtoolsPluginModule.forRoot(),
                    NgxsFormPluginModule.forRoot(),
                    TranslocoMessageFormatModule.init(),
                ],
                exports: [
                    BrowserModule,
                    TranslocoModule,
                ],
                providers: [
                    {
                        provide: HTTP_INTERCEPTORS,
                        useClass: TokenInterceptor,
                        multi: true
                    },
                    {
                        provide: TRANSLOCO_CONFIG,
                        useValue: {
                            availableLangs: ['en', 'es'],
                            defaultLang: 'en',
                            prodMode: environment.production,
                        }
                    },
                    customTranslocoMissingHandler,
                    translocoLoader,
                    LogsService,
                    { provide: StorageBucket, useValue: environment.firebase.storageBucket }
                ],
            }]
    }], null, null); })();
