import {Injectable} from '@angular/core';
import {Action, State, StateContext, Store} from '@ngxs/store';
import {DisplayError, ResetError, StartLoading, StopLoading} from '@store/app/app.actions';

export interface AppStateModel {
  isLoading: boolean;
  error: string;
}

const initialState: AppStateModel = {
  isLoading: false,
  error: null
};

@Injectable()
@State<AppStateModel>({
  name: 'app',
  defaults: initialState
})
export class AppState {

  @Action(StartLoading)
  async startLoading({patchState}: StateContext<AppStateModel>) {
    return patchState({isLoading: true});
  }

  @Action(StopLoading)
  async stopLoading({patchState}: StateContext<AppStateModel>) {
    return patchState({isLoading: false});
  }

  @Action(DisplayError)
  async displayError({patchState}: StateContext<AppStateModel>, {message}: DisplayError) {
    return patchState({error: message});
  }

  @Action(ResetError)
  async resetError({patchState}: StateContext<AppStateModel>) {
    return patchState({error: null});
  }
}
