import { Pipe } from '@angular/core';
import * as i0 from "@angular/core";
export class UserPipe {
    transform(value, ...args) {
        const { firebaseUser, doc } = value;
        return {
            uid: firebaseUser.uid,
            displayName: firebaseUser.displayName,
            photoURL: firebaseUser.photoURL,
            isAdmin: Boolean(doc && doc.isAdmin),
            apiToken: doc ? doc.apiToken : null,
            createdAt: doc ? doc.createdAt.toDate() : new Date(),
            updatedAt: doc ? doc.updatedAt.toDate() : new Date(),
        };
    }
}
UserPipe.ɵfac = function UserPipe_Factory(t) { return new (t || UserPipe)(); };
UserPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "user", type: UserPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(UserPipe, [{
        type: Pipe,
        args: [{
                name: 'user'
            }]
    }], null, null); })();
