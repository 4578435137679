export const environment = {
  production: true,
  apiEndpoint: 'https://us-central1-pma-auth-dev.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyDkHaaYVA1M87-6sTDS5bAgsE4wXTVzNWs',
    authDomain: 'pma-auth-dev.firebaseapp.com',
    databaseURL: 'https://pma-auth-dev.firebaseio.com',
    projectId: 'pma-auth-dev',
    storageBucket: 'pma-auth-dev.appspot.com',
    messagingSenderId: '584386727065',
    appId: '1:584386727065:web:7c1f7265357b6d2f7fbc08',
    measurementId: 'G-JEWPCJ5NTD'
  }
};
