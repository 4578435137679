import {NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import {AngularFireAuthGuard, AngularFireAuthGuardModule, loggedIn, redirectLoggedInTo} from '@angular/fire/auth-guard';
import {pipe} from 'rxjs';
import {map, tap} from 'rxjs/operators';


const redirectUnauthorizedToLogin = (route: ActivatedRouteSnapshot) => {
  const path = route.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
  const params = route.queryParams;

  return pipe(
    loggedIn,
    tap((isLoggedIn) => {
      if (!isLoggedIn) {
        console.log('Saving afterLogin');
        sessionStorage.setItem('afterLogin', JSON.stringify({path, params}));
      }
    }),
    map(loggedIn => loggedIn || ['/'])
  );
};

const loginRoute =   {
  loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
  canActivate: [AngularFireAuthGuard],
  data: {authGuardPipe: () => redirectLoggedInTo(['organizations'])}
};

const routes: Routes = [
  {
    path: 'organizations',
    loadChildren: () => import('./organizations/organizations.module').then(mod => mod.OrganizationsModule),
    canActivate: [AngularFireAuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin}
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then(mod => mod.ProductsModule)
  },
  {
    path: 'plans',
    loadChildren: () => import('./plans/plans.module').then(mod => mod.PlansModule)
  },
  {
    path: '',
    ...loginRoute
  },
  {
    path: ':type',
    ...loginRoute
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    AngularFireAuthGuardModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
