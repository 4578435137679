import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularFireAuthGuard, AngularFireAuthGuardModule, loggedIn, redirectLoggedInTo } from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
const redirectUnauthorizedToLogin = (route) => {
    const path = route.pathFromRoot.map(v => v.url.map(segment => segment.toString()).join('/')).join('/');
    const params = route.queryParams;
    return pipe(loggedIn, tap((isLoggedIn) => {
        if (!isLoggedIn) {
            console.log('Saving afterLogin');
            sessionStorage.setItem('afterLogin', JSON.stringify({ path, params }));
        }
    }), map(loggedIn => loggedIn || ['/']));
};
const loginRoute = {
    loadChildren: () => import('./home/home.module').then(mod => mod.HomeModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: () => redirectLoggedInTo(['organizations']) }
};
const routes = [
    {
        path: 'organizations',
        loadChildren: () => import('./organizations/organizations.module').then(mod => mod.OrganizationsModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin }
    },
    {
        path: 'products',
        loadChildren: () => import('./products/products.module').then(mod => mod.ProductsModule)
    },
    {
        path: 'plans',
        loadChildren: () => import('./plans/plans.module').then(mod => mod.PlansModule)
    },
    Object.assign({ path: '' }, loginRoute),
    Object.assign({ path: ':type' }, loginRoute),
];
export class AppRoutingModule {
}
AppRoutingModule.ɵmod = i0.ɵɵdefineNgModule({ type: AppRoutingModule });
AppRoutingModule.ɵinj = i0.ɵɵdefineInjector({ factory: function AppRoutingModule_Factory(t) { return new (t || AppRoutingModule)(); }, imports: [[
            RouterModule.forRoot(routes),
            AngularFireAuthGuardModule
        ],
        RouterModule] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AppRoutingModule, { imports: [i1.RouterModule, AngularFireAuthGuardModule], exports: [RouterModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppRoutingModule, [{
        type: NgModule,
        args: [{
                imports: [
                    RouterModule.forRoot(routes),
                    AngularFireAuthGuardModule
                ],
                exports: [RouterModule]
            }]
    }], null, null); })();
