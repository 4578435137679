import {NotificationAction, NotificationModel} from '../../../../../shared/types/notification';

export class StreamNotifications {
  static readonly type = '[Notifications] Stream';
}

export class PatchStreamedNotifications {
  static readonly type = '[Notifications] Patch Synced Data';

  constructor(public notifications: NotificationModel[]) {
  }
}

export class MarkNotificationsAsRead {
  static readonly type = '[Notifications] Mark Notifications a read';
}

export class PatchNotificationStatus {
  static readonly type = '[Notifications] Patch Status';

  constructor(public notification: NotificationModel, public action: NotificationAction) {
  }
}
