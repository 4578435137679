import { Pipe } from '@angular/core';
import { DocumentPipe } from '@shared/pipes/document/document.pipe';
import { NotificationAction, NotificationType } from '../../../../../shared/types/notification';
import * as i0 from "@angular/core";
export class NotificationPipe extends DocumentPipe {
    transform(doc) {
        if ("organization" in doc) {
            delete doc.organization;
        }
        if ("user" in doc) {
            delete doc.user;
        }
        delete doc.to;
        const transformed = super.transform(doc);
        transformed.actions = {
            [NotificationType.Invitation]: [
                { value: NotificationAction.Decline },
                { value: NotificationAction.Accept, primary: true },
            ],
        }[doc.type];
        return transformed;
    }
}
NotificationPipe.ɵfac = function NotificationPipe_Factory(t) { return ɵNotificationPipe_BaseFactory(t || NotificationPipe); };
NotificationPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "notification", type: NotificationPipe, pure: true });
const ɵNotificationPipe_BaseFactory = i0.ɵɵgetInheritedFactory(NotificationPipe);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NotificationPipe, [{
        type: Pipe,
        args: [{
                name: 'notification'
            }]
    }], null, null); })();
