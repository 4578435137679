import { Pipe } from '@angular/core';
import { DocumentPipe } from '@shared/pipes/document/document.pipe';
import * as i0 from "@angular/core";
export class LogPipe extends DocumentPipe {
    transform(doc, ...args) {
        return super.transform(doc);
    }
}
LogPipe.ɵfac = function LogPipe_Factory(t) { return ɵLogPipe_BaseFactory(t || LogPipe); };
LogPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "log", type: LogPipe, pure: true });
const ɵLogPipe_BaseFactory = i0.ɵɵgetInheritedFactory(LogPipe);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LogPipe, [{
        type: Pipe,
        args: [{
                name: 'log'
            }]
    }], null, null); })();
