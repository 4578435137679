import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {WithId} from '../../../../../shared/interfaces/with-id';
import {ConnectorProviderDocument, ConnectorProviderModel} from '../../../../../shared/types/connector-provider';
import {ConnectorModel} from '../../../../../shared/types/connector/connector.model';
import {Connector} from '../../../../../shared/types/connector';

@Pipe({
  name: 'connectorsProvider'
})
export class ConnectorPipe extends DocumentPipe<Connector, ConnectorModel> implements PipeTransform {

  transform(doc: Connector, ...args: any[]): ConnectorModel {
    const transformed = super.transform(doc);

    transformed.logoPath = `assets/images/providers/${doc.logoName}`;

    return transformed;
  }

}
