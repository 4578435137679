import { Component, Input } from '@angular/core';
import * as i0 from "@angular/core";
export class IconComponent {
}
IconComponent.ɵfac = function IconComponent_Factory(t) { return new (t || IconComponent)(); };
IconComponent.ɵcmp = i0.ɵɵdefineComponent({ type: IconComponent, selectors: [["app-icon"]], inputs: { name: "name" }, decls: 1, vars: 1, consts: [[3, "src"]], template: function IconComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "img", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("src", "assets/icons/" + ctx.name + ".png", i0.ɵɵsanitizeUrl);
    } }, styles: ["img[_ngcontent-%COMP%]{height:1em}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IconComponent, [{
        type: Component,
        args: [{
                selector: 'app-icon',
                templateUrl: './icon.component.html',
                styleUrls: ['./icon.component.scss']
            }]
    }], null, { name: [{
            type: Input
        }] }); })();
