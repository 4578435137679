import {Pipe, PipeTransform} from '@angular/core';
import {User as FirebaseUser} from 'firebase/app';
import {UserDocument, UserModel} from '../../../../../shared/types/user';

@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {

  transform(value: { firebaseUser: FirebaseUser, doc: UserDocument }, ...args: any[]): UserModel {
    const {firebaseUser, doc} = value;

    return {
      uid: firebaseUser.uid,
      displayName: firebaseUser.displayName,
      photoURL: firebaseUser.photoURL,
      isAdmin: Boolean(doc && doc.isAdmin),
      apiToken: doc ? doc.apiToken : null,
      createdAt: doc ? doc.createdAt.toDate() : new Date(),
      updatedAt: doc ? doc.updatedAt.toDate() : new Date(),
    };
  }

}
