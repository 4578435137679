import {Pipe, PipeTransform} from '@angular/core';
import {DocumentPipe} from '@shared/pipes/document/document.pipe';
import {WithId} from '../../../../../shared/interfaces/with-id';
import {OrganizationDocument, OrganizationModel} from '../../../../../shared/types/organization';

@Pipe({
  name: 'organization'
})
export class OrganizationPipe extends DocumentPipe<OrganizationDocument, OrganizationModel> implements PipeTransform {
  transform(doc: WithId<OrganizationDocument>, ...args: any[]): OrganizationModel {
    doc.connections = new Map(Object.entries(doc.connections));
    doc.connections.forEach((connection: any) => {
      connection.id = connection.ref.id;
      delete connection.ref;
    });

    const newDoc: OrganizationModel = super.transform(doc) as OrganizationModel;
    newDoc.billing.isActive = ['live', 'trial'].indexOf(newDoc.billing.status) > -1;
    return newDoc;
  }
}
