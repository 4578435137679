import {Inject, Injectable, InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/analytics';
import {NGXS_PLUGINS, NgxsPlugin} from '@ngxs/store';
import {pick} from 'lodash';
import {AngularFireAuth} from '@angular/fire/auth';

export const NGXS_INTERCEPTOR_PLUGIN_OPTIONS = new InjectionToken('NGXS_INTERCEPTOR_PLUGIN_OPTIONS');

@Injectable()
export class InterceptorPlugin implements NgxsPlugin {
  private ignoredActions = [
    '@@INIT',
    '@@UPDATE_STATE',
    '[Forms] Update Form Dirty',
    '[Forms] Update Form Errors',
    '[Forms] Update Form Status',
    '[Forms] Update Form Value',
    // '[Router] Navigate',
    '[Router] RouterCancel',
    // '[Router] RouterNavigation',
    '[Router] RouterDataResolved'
  ];

  constructor(@Inject(NGXS_INTERCEPTOR_PLUGIN_OPTIONS)
              private options: any,
              private afAuth: AngularFireAuth,
              private analytics: AngularFireAnalytics) {
  }

  /**
   * TODO docs
   * @param before
   * @param action
   * @param next
   */
  handle(before, action, next) {
    const currentUid = before.auth && before.auth.isLoggedIn && before.auth.user ? before.auth.user.uid : undefined;
    const currentOrganizationId = before.organizations && before.organizations.selectedId ? before.organizations.selectedId : undefined;

    // Identifying variables for user and organization
    const extraParams = {hub_user_id: currentUid, hub_organization_id: currentOrganizationId};

    const type = action.type || action.constructor.type;
    const params = this.getEventParams(action, type);

    if (!this.ignoredActions.includes(type)) {
      this.analytics.logEvent(type, {...extraParams, ...params})
        // .then(() => console.debug('logEvent', {type, params}))
        .catch(err => console.error(err));
    }
    return next(before, action);
  }

  /**
   * TODO docs
   * @param action
   * @param type
   */
  private getEventParams(action, type) {
    if (type.includes('[Router]')) {
      return pick(action.event, ['id', 'url', 'urlAfterRedirects']);
    }

    if (action.constructor.eventParams) {
      return pick(action, action.constructor.eventParams);
    }

    return {};
  }
}

@NgModule()
export class NgxsInterceptorPluginModule {
  static forRoot(config?: any): ModuleWithProviders<NgxsInterceptorPluginModule> {
    return {
      ngModule: NgxsInterceptorPluginModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: InterceptorPlugin,
          multi: true
        },
        {
          provide: NGXS_INTERCEPTOR_PLUGIN_OPTIONS,
          useValue: config
        }
      ]
    }
  }
}
