import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { Action, State } from '@ngxs/store';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { ResetLogs, StreamLogs } from '@store/logs/logs.actions';
import { Subject } from 'rxjs';
import { DisplayError } from '@store/app/app.actions';
import * as i0 from "@angular/core";
import * as i1 from "@core/services/logs/logs.service";
const initialState = {
    isInitialized: false,
    collection: [],
};
let LogsState = class LogsState {
    constructor(service) {
        this.service = service;
    }
    stream({ patchState, dispatch }, { entityPath }) {
        this.subscriber = new Subject();
        return this.service.list([entityPath]).pipe(tap(logs => patchState({
            isInitialized: true,
            collection: logs,
        })), catchError(e => dispatch(new DisplayError(e))), takeUntil(this.subscriber));
    }
    resetLogs({ patchState }) {
        this.subscriber.next();
        this.subscriber.complete();
        patchState(initialState);
    }
};
LogsState.ɵfac = function LogsState_Factory(t) { return new (t || LogsState)(i0.ɵɵinject(i1.LogsService)); };
LogsState.ɵprov = i0.ɵɵdefineInjectable({ token: LogsState, factory: LogsState.ɵfac });
__decorate([
    Action(StreamLogs)
], LogsState.prototype, "stream", null);
__decorate([
    Action(ResetLogs)
], LogsState.prototype, "resetLogs", null);
LogsState = __decorate([
    State({
        name: 'logs',
        defaults: initialState
    })
], LogsState);
export { LogsState };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LogsState, [{
        type: Injectable
    }], function () { return [{ type: i1.LogsService }]; }, { stream: [], resetLogs: [] }); })();
