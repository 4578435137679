import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {distinctUntilChanged, filter, first, map, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ResetLogs, StreamLogs} from '@store/logs/logs.actions';
import {LogsDialogComponent} from './components/logs-dialog/logs-dialog.component';
import {Select, Store} from '@ngxs/store';
import {Observable, of} from 'rxjs';
import {MatProgressSpinner, MatSpinner} from '@angular/material/progress-spinner';
import {PromptDialogComponent} from '@shared/components/prompt-dialog/prompt-dialog.component';
import {ResetError} from '@store/app/app.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RedirectAfterSignIn} from '@store/auth/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pma';

  @Select(state => state.app.isLoading) isLoading$: Observable<boolean>;
  @Select(state => state.app.error) error$: Observable<string>;
  @Select(state => state.auth) auth$: Observable<any>;
  @Select(state => Boolean(state.auth.isInitialized && !state.auth.isLoggedIn)) isLoggedOut$: Observable<boolean>;

  loaderDialog: MatDialogRef<MatProgressSpinner>;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private store: Store) {
  }

  ngOnInit() {
    this.manageLogsDialog();
    this.manageLoading();
    this.manageAppErrors();
    this.manageAfterLoginRedirect();
  }

  manageLoading() {
    this.isLoading$.pipe(
      filter(isLoading => isLoading || Boolean(this.loaderDialog)),
      tap(isLoading => {
        if (isLoading) {
          this.loaderDialog = this.dialog.open(MatSpinner, {panelClass: 'app-loader-dialog'});
        } else {
          this.loaderDialog.close();
          delete this.loaderDialog;
        }
      })
    ).subscribe();
  }

  manageLogsDialog() {
    this.route.queryParams.pipe(
      map(params => params.logs),
      distinctUntilChanged(),
      filter(Boolean),
      tap((logs: string) => this.openLogsDialog(logs)),
    ).subscribe();
  }

  manageAppErrors() {
    this.error$.pipe(
      filter(Boolean),
      tap((error: string) => {
        this.store.dispatch(new ResetError());

        this.dialog.open(PromptDialogComponent, {
          panelClass: 'app-error-dialog',
          data: {
            title: 'error',
            content: error
          }
        });

        this.snackBar.open(error, null, {
          panelClass: 'danger-background',
          duration: 5000
        });

        return of(error);
      })
    ).subscribe();
  }

  manageAfterLoginRedirect() {
    if (sessionStorage.getItem('afterLogin')) {
      this.store.dispatch(RedirectAfterSignIn);
    }
  }

  openLogsDialog(entityPath: string) {
    // Stream all logs for entity
    this.store.dispatch(new StreamLogs(entityPath));

    // Open Dialog
    const dialogRef = this.dialog.open(LogsDialogComponent, {panelClass: ['main-dialog', 'logs-dialog']});

    // After Dialog closes, stop streaming
    dialogRef.afterClosed().pipe(
      first(),
      tap(() => this.store.dispatch(new ResetLogs())),
      tap(() => {
        const params = {...this.route.snapshot.queryParams};
        delete params.logs;

        this.router.navigate([], {queryParams: params});
      })
    ).subscribe();
  }

}
