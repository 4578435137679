import { Component, Input } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/core";
import * as i2 from "../picture/picture.component";
export class ProviderCardComponent {
    constructor() {
        this.counter = {};
    }
}
ProviderCardComponent.ɵfac = function ProviderCardComponent_Factory(t) { return new (t || ProviderCardComponent)(); };
ProviderCardComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ProviderCardComponent, selectors: [["app-provider-card"]], inputs: { provider: "provider", counter: "counter" }, decls: 8, vars: 13, consts: [["matRipple", "", 1, "provider-container", 3, "matRippleColor"], [1, "logo-wrapper"], [1, "badge", 3, "hidden"], [3, "srcset", "encapsulate"], [1, "provider-name"]], template: function ProviderCardComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelementStart(2, "div", 1);
        i0.ɵɵelementStart(3, "div", 2);
        i0.ɵɵtext(4);
        i0.ɵɵelementEnd();
        i0.ɵɵelement(5, "app-picture", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "strong", 4);
        i0.ɵɵtext(7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵclassMapInterpolate1("provider ", ctx.provider.type, "");
        i0.ɵɵstyleProp("background-color", ctx.provider.color.base + "14");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("matRippleColor", ctx.provider.color.base + "8a");
        i0.ɵɵadvance(2);
        i0.ɵɵstyleProp("background-color", ctx.provider.color.base)("color", ctx.provider.color.contrast);
        i0.ɵɵproperty("hidden", !ctx.counter[ctx.provider.type]);
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate1(" ", ctx.counter[ctx.provider.type], " ");
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("srcset", ctx.provider.logoPath)("encapsulate", "height");
        i0.ɵɵadvance(1);
        i0.ɵɵstyleProp("color", ctx.provider.color.text || ctx.provider.color.base);
        i0.ɵɵadvance(1);
        i0.ɵɵtextInterpolate(ctx.provider.title);
    } }, directives: [i1.MatRipple, i2.PictureComponent], styles: [".provider[_ngcontent-%COMP%]{cursor:pointer;border-radius:8px;overflow:hidden;height:100%}.provider-container[_ngcontent-%COMP%]{padding:32px;display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}.logo-wrapper[_ngcontent-%COMP%]{background-color:#fff;border-radius:100%;display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center;-webkit-box-pack:center;justify-content:center;width:96px;height:96px;margin-bottom:32px;position:relative;z-index:1}.logo-wrapper[_ngcontent-%COMP%]   .badge[_ngcontent-%COMP%]{line-height:32px;width:32px;text-align:center;font-weight:700;font-size:16px;border-radius:100%;background-color:#333;position:absolute;top:0;right:0}.logo-wrapper[_ngcontent-%COMP%]   app-picture[_ngcontent-%COMP%]{height:32px}button[_ngcontent-%COMP%] + button[_ngcontent-%COMP%]{-webkit-margin-start:8px;margin-inline-start:8px}.provider-name[_ngcontent-%COMP%]{font-weight:500;text-align:center}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ProviderCardComponent, [{
        type: Component,
        args: [{
                selector: 'app-provider-card',
                templateUrl: './provider-card.component.html',
                styleUrls: ['./provider-card.component.scss']
            }]
    }], null, { provider: [{
            type: Input
        }], counter: [{
            type: Input
        }] }); })();
