import { TRANSLOCO_LOADER, TRANSLOCO_MISSING_HANDLER } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class HttpLoader {
    constructor(http) {
        this.http = http;
    }
    getTranslation(langPath) {
        return this.http.get(`/assets/i18n/${langPath}.json`);
    }
}
HttpLoader.ɵfac = function HttpLoader_Factory(t) { return new (t || HttpLoader)(i0.ɵɵinject(i1.HttpClient)); };
HttpLoader.ɵprov = i0.ɵɵdefineInjectable({ token: HttpLoader, factory: HttpLoader.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(HttpLoader, [{
        type: Injectable,
        args: [{ providedIn: 'root' }]
    }], function () { return [{ type: i1.HttpClient }]; }, null); })();
export const translocoLoader = { provide: TRANSLOCO_LOADER, useClass: HttpLoader };
export class CustomTranslocoMissingHandler {
    handle(key, config) {
        console.error('Missing translation key', key);
        if (key.indexOf('config.fields') > -1) {
            return null;
        }
        return key;
    }
}
export const customTranslocoMissingHandler = {
    provide: TRANSLOCO_MISSING_HANDLER,
    useClass: CustomTranslocoMissingHandler
};
