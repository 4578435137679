import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AuthStateModel} from '@store/auth/auth.state';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavComponent implements OnInit {

  @Select(state => state.auth) authState$: Observable<AuthStateModel>;


  constructor(private store: Store) {
  }

  ngOnInit() {
    //
  }
}
