import {Injectable} from '@angular/core';
import {AuthService} from '@core/services/auth/auth.service';
import {Navigate} from '@ngxs/router-plugin';
import {Action, NgxsOnInit, State, StateContext} from '@ngxs/store';
import {PatchStreamedAuth, RedirectAfterSignIn, RedirectThirdParty, SignInWithGoogle, SignOut, StreamAuth} from '@store/auth/auth.actions';
import {filter, first, tap} from 'rxjs/operators';
import {UserModel} from '../../../../../shared/types/user';
import {DisplayError, StartLoading, StopLoading} from '@store/app/app.actions';
import {Router} from '@angular/router';

export interface AuthStateModel {
  isInitialized: boolean;
  isLoggedIn: boolean;
  user: UserModel;
}

const initialState: AuthStateModel = {
  isInitialized: false,
  isLoggedIn: undefined,
  user: undefined
};

@Injectable()
@State<AuthStateModel>({
  name: 'auth',
  defaults: initialState
})
export class AuthState implements NgxsOnInit {

  constructor(private auth: AuthService, private router: Router) {
  }

  /**
   * On initializing AuthState
   * @param dispatch new action
   */
  ngxsOnInit({dispatch}: StateContext<AuthStateModel>) {
    dispatch(StreamAuth);
  }

  /**
   * Listen to authentication state and updates AuthState accordingly.
   * @param dispatch new action
   */
  @Action(StreamAuth)
  stream({dispatch}: StateContext<AuthStateModel>) {
    return this.auth.user$.pipe(
      tap((user: UserModel) => dispatch(
        new PatchStreamedAuth({isInitialized: true, isLoggedIn: Boolean(user), user})
      )),
    );
  }

  /**
   * Open a popup to sign in with Google Provider.
   */
  @Action(SignInWithGoogle)
  async signInWithGoogle({dispatch}: StateContext<AuthStateModel>, {isPopup}: SignInWithGoogle) {
    try {
      dispatch(StartLoading);
      await this.auth.signInWithGoogle(isPopup);
      dispatch(RedirectAfterSignIn);
    } catch (e) {
      if (e.code !== 'auth/popup-closed-by-user') {
        dispatch(new DisplayError(e));
      }
    } finally {
      dispatch(StopLoading);
    }
  }

  /**
   * Redirect the user after they sign in
   */
  @Action(RedirectAfterSignIn)
  async redirectUser({dispatch}: StateContext<AuthStateModel>) {
    this.auth.isAuthenticated().pipe(
      filter(Boolean),
      first(),
      tap(() => {
        // Get redirect url if exists after login
        const afterLogin = sessionStorage.getItem('afterLogin');
        const navigate = afterLogin ? JSON.parse(afterLogin) : {path: '/organizations'};

        // Make sure to be the last redirect
        setTimeout(() => dispatch(new Navigate([navigate.path], navigate.params)), 0);

        if (afterLogin) {
          sessionStorage.removeItem('afterLogin');
        }
      })
    ).subscribe();
  }

  /**
   * Sign out the current authenticated user.
   */
  @Action(SignOut)
  async signOut() {
    await this.auth.signOut();
    return this.router.navigate(['/']);
  }

  /**
   * Patch the current state.
   * Note - Since StreamAuth never completes, then Redux DevTools won't be able to report when it changes the state.
   * Therefore, instead of directly changing its state, it will call this action.
   * @param patchState of the current state
   * @param payload of the current state
   */
  @Action(PatchStreamedAuth)
  patch({patchState}: StateContext<AuthStateModel>, {payload}: PatchStreamedAuth) {
    return patchState(payload);
  }

  /**
   * Redirect user to third party they came from for token exchange
   */
  @Action(RedirectThirdParty)
  redirectThirdParty({dispatch}: StateContext<AuthStateModel>, {redirectUri, state}: RedirectThirdParty) {
    dispatch(new StartLoading());
    const redirect = new URL(redirectUri);
    redirect.searchParams.append('code', this.auth.uid);
    redirect.searchParams.append('state', state);
    location.href = redirect.href;
  }
}
