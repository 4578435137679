import {Component, OnInit} from '@angular/core';

interface NavMenu {
  title: string;
  href?: string;
  children?: NavMenu[];
}

@Component({
  selector: 'app-static-nav-items',
  templateUrl: './static-nav-items.component.html',
  styleUrls: ['./static-nav-items.component.scss']
})
export class StaticNavItemsComponent implements OnInit {

  menu: NavMenu[] = [
    // {
    //   title: 'products',
    //   children: [
    //     {title: 'dataStudio', href: '/products/data-studio'},
    //     // {title: 'googleAnalytics', href: '/products/google-analytics'}
    //   ],
    // },
    {
      title: 'home',
      href: '/',
    },
    {
      title: 'plans',
      href: '/plans',
    },
    {
      title: 'support',
      href: 'https://support.powermyanalytics.com/portal/kb',
    }
  ];

  constructor() {
  }

  ngOnInit() {
  }

}
