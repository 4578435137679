import { Pipe } from '@angular/core';
import { DocumentPipe } from '@shared/pipes/document/document.pipe';
import * as i0 from "@angular/core";
export class ConnectorsProviderPipe extends DocumentPipe {
    transform(doc, ...args) {
        const transformed = super.transform(doc);
        transformed.logoPath = `assets/images/providers/${doc.logoName}`;
        return transformed;
    }
}
ConnectorsProviderPipe.ɵfac = function ConnectorsProviderPipe_Factory(t) { return ɵConnectorsProviderPipe_BaseFactory(t || ConnectorsProviderPipe); };
ConnectorsProviderPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "connectorsProvider", type: ConnectorsProviderPipe, pure: true });
const ɵConnectorsProviderPipe_BaseFactory = i0.ɵɵgetInheritedFactory(ConnectorsProviderPipe);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ConnectorsProviderPipe, [{
        type: Pipe,
        args: [{
                name: 'connectorsProvider'
            }]
    }], null, null); })();
