import { Pipe } from '@angular/core';
import * as firebase from 'firebase/app';
import * as i0 from "@angular/core";
export class DocumentPipe {
    static deepTransform(doc, cache) {
        if (!doc) {
            return doc;
        }
        if (doc instanceof firebase.firestore.Timestamp) {
            return doc.toDate();
        }
        if (Array.isArray(doc)) {
            return doc.map(av => DocumentPipe.deepTransform(av, cache));
        }
        if (doc instanceof Map) {
            return new Map(Array.from(doc).map(([k, v]) => ([k, DocumentPipe.deepTransform(v, cache)])));
        }
        if (typeof doc === 'object' && !doc.firestore && !cache.has(doc) && !(doc instanceof Date)) {
            cache.add(doc);
            const newDoc = Object.assign({}, doc);
            Object.keys(newDoc).forEach(k => {
                newDoc[k] = DocumentPipe.deepTransform(newDoc[k], cache);
            });
            return newDoc;
        }
        if (doc.firestore) {
            return null;
        }
        return doc;
    }
    transform(doc, ...args) {
        return DocumentPipe.deepTransform(doc, new Set());
    }
}
DocumentPipe.ɵfac = function DocumentPipe_Factory(t) { return new (t || DocumentPipe)(); };
DocumentPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "document", type: DocumentPipe, pure: true });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DocumentPipe, [{
        type: Pipe,
        args: [{
                name: 'document'
            }]
    }], null, null); })();
