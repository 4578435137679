import { __decorate } from "tslib";
import { Component } from '@angular/core';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { ResetLogs, StreamLogs } from '@store/logs/logs.actions';
import { LogsDialogComponent } from './components/logs-dialog/logs-dialog.component';
import { Select } from '@ngxs/store';
import { of } from 'rxjs';
import { MatSpinner } from '@angular/material/progress-spinner';
import { PromptDialogComponent } from '@shared/components/prompt-dialog/prompt-dialog.component';
import { ResetError } from '@store/app/app.actions';
import { RedirectAfterSignIn } from '@store/auth/auth.actions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/material/snack-bar";
import * as i3 from "@angular/material/dialog";
import * as i4 from "@ngxs/store";
import * as i5 from "./components/nav/nav.component";
import * as i6 from "./components/footer/footer.component";
export class AppComponent {
    constructor(router, route, snackBar, dialog, store) {
        this.router = router;
        this.route = route;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.store = store;
        this.title = 'pma';
    }
    ngOnInit() {
        this.manageLogsDialog();
        this.manageLoading();
        this.manageAppErrors();
        this.manageAfterLoginRedirect();
    }
    manageLoading() {
        this.isLoading$.pipe(filter(isLoading => isLoading || Boolean(this.loaderDialog)), tap(isLoading => {
            if (isLoading) {
                this.loaderDialog = this.dialog.open(MatSpinner, { panelClass: 'app-loader-dialog' });
            }
            else {
                this.loaderDialog.close();
                delete this.loaderDialog;
            }
        })).subscribe();
    }
    manageLogsDialog() {
        this.route.queryParams.pipe(map(params => params.logs), distinctUntilChanged(), filter(Boolean), tap((logs) => this.openLogsDialog(logs))).subscribe();
    }
    manageAppErrors() {
        this.error$.pipe(filter(Boolean), tap((error) => {
            this.store.dispatch(new ResetError());
            this.dialog.open(PromptDialogComponent, {
                panelClass: 'app-error-dialog',
                data: {
                    title: 'error',
                    content: error
                }
            });
            this.snackBar.open(error, null, {
                panelClass: 'danger-background',
                duration: 5000
            });
            return of(error);
        })).subscribe();
    }
    manageAfterLoginRedirect() {
        if (sessionStorage.getItem('afterLogin')) {
            this.store.dispatch(RedirectAfterSignIn);
        }
    }
    openLogsDialog(entityPath) {
        // Stream all logs for entity
        this.store.dispatch(new StreamLogs(entityPath));
        // Open Dialog
        const dialogRef = this.dialog.open(LogsDialogComponent, { panelClass: ['main-dialog', 'logs-dialog'] });
        // After Dialog closes, stop streaming
        dialogRef.afterClosed().pipe(first(), tap(() => this.store.dispatch(new ResetLogs())), tap(() => {
            const params = Object.assign({}, this.route.snapshot.queryParams);
            delete params.logs;
            this.router.navigate([], { queryParams: params });
        })).subscribe();
    }
}
AppComponent.ɵfac = function AppComponent_Factory(t) { return new (t || AppComponent)(i0.ɵɵdirectiveInject(i1.Router), i0.ɵɵdirectiveInject(i1.ActivatedRoute), i0.ɵɵdirectiveInject(i2.MatSnackBar), i0.ɵɵdirectiveInject(i3.MatDialog), i0.ɵɵdirectiveInject(i4.Store)); };
AppComponent.ɵcmp = i0.ɵɵdefineComponent({ type: AppComponent, selectors: [["app-root"]], decls: 4, vars: 0, consts: [["id", "wrap-router"]], template: function AppComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelement(0, "app-nav");
        i0.ɵɵelementStart(1, "div", 0);
        i0.ɵɵelement(2, "router-outlet");
        i0.ɵɵelementEnd();
        i0.ɵɵelement(3, "app-footer");
    } }, directives: [i5.NavComponent, i1.RouterOutlet, i6.FooterComponent], styles: ["[_nghost-%COMP%]{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-direction:column}#wrap-router[_ngcontent-%COMP%]{-webkit-box-flex:1;flex-grow:1;margin-bottom:32px}"] });
__decorate([
    Select(state => state.app.isLoading)
], AppComponent.prototype, "isLoading$", void 0);
__decorate([
    Select(state => state.app.error)
], AppComponent.prototype, "error$", void 0);
__decorate([
    Select(state => state.auth)
], AppComponent.prototype, "auth$", void 0);
__decorate([
    Select(state => Boolean(state.auth.isInitialized && !state.auth.isLoggedIn))
], AppComponent.prototype, "isLoggedOut$", void 0);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppComponent, [{
        type: Component,
        args: [{
                selector: 'app-root',
                templateUrl: './app.component.html',
                styleUrls: ['./app.component.scss']
            }]
    }], function () { return [{ type: i1.Router }, { type: i1.ActivatedRoute }, { type: i2.MatSnackBar }, { type: i3.MatDialog }, { type: i4.Store }]; }, { isLoading$: [], error$: [], auth$: [], isLoggedOut$: [] }); })();
