import { Pipe } from '@angular/core';
import { DocumentPipe } from '@shared/pipes/document/document.pipe';
import * as i0 from "@angular/core";
export class OrganizationPipe extends DocumentPipe {
    transform(doc, ...args) {
        doc.connections = new Map(Object.entries(doc.connections));
        doc.connections.forEach((connection) => {
            connection.id = connection.ref.id;
            delete connection.ref;
        });
        const newDoc = super.transform(doc);
        newDoc.billing.isActive = ['live', 'trial'].indexOf(newDoc.billing.status) > -1;
        return newDoc;
    }
}
OrganizationPipe.ɵfac = function OrganizationPipe_Factory(t) { return ɵOrganizationPipe_BaseFactory(t || OrganizationPipe); };
OrganizationPipe.ɵpipe = i0.ɵɵdefinePipe({ name: "organization", type: OrganizationPipe, pure: true });
const ɵOrganizationPipe_BaseFactory = i0.ɵɵgetInheritedFactory(OrganizationPipe);
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(OrganizationPipe, [{
        type: Pipe,
        args: [{
                name: 'organization'
            }]
    }], null, null); })();
