import { Injectable } from '@angular/core';
import { BaseService } from '@core/services/base.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@shared/pipes/logs/log.pipe";
export class LogsService extends BaseService {
    constructor(afs, transformer) {
        super();
        this.afs = afs;
        this.transformer = transformer;
    }
    list(entityPaths, types, after, limit = 100) {
        const entityRefs = entityPaths.map(entityPath => this.afs.doc(entityPath).ref);
        return this.afs.collection('logs', ref => {
            let q = ref;
            if (entityRefs && entityRefs.length) {
                q = q.where('ref', 'in', entityRefs);
            }
            if (types && types.length) {
                q = q.where('type', 'in', types);
            }
            if (after) {
                q = q.where('createdAt', '>', after);
            }
            return q.orderBy('createdAt', 'desc').limit(limit);
        }).valueChanges().pipe(map((logs) => logs.map(this.transformer.transform)));
    }
}
LogsService.ɵfac = function LogsService_Factory(t) { return new (t || LogsService)(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.LogPipe)); };
LogsService.ɵprov = i0.ɵɵdefineInjectable({ token: LogsService, factory: LogsService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LogsService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.AngularFirestore }, { type: i2.LogPipe }]; }, null); })();
