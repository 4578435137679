import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class BaseComponent {
    constructor() {
        this.ngUnsubscribe = new Subject();
    }
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
BaseComponent.ɵfac = function BaseComponent_Factory(t) { return new (t || BaseComponent)(); };
BaseComponent.ɵcmp = i0.ɵɵdefineComponent({ type: BaseComponent, selectors: [["app-base"]], decls: 2, vars: 0, template: function BaseComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "p");
        i0.ɵɵtext(1, " base works! ");
        i0.ɵɵelementEnd();
    } }, encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(BaseComponent, [{
        type: Component,
        args: [{
                selector: 'app-base',
                template: `
    <p>
      base works!
    </p>
  `,
                styles: []
            }]
    }], null, null); })();
