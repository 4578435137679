<ng-container *transloco="let t; read: 'app.nav'">
  <li *ngIf="authState$ | async as authState" class="auth-menu">
    <ng-container *ngIf="authState.isLoggedIn === undefined">
      <mat-spinner diameter="32"></mat-spinner>
    </ng-container>

    <ng-container *ngIf="authState.isLoggedIn === false">
      <button mat-raised-button color="primary" class="auth-button" [matMenuTriggerFor]="guestMenu">
        {{ t('actions.signIn') }}
      </button>
      <mat-menu #guestMenu="matMenu" xPosition="before" class="guest-menu">
        <app-sign-in-with-google (click)="signInWithGoogle()"></app-sign-in-with-google>
      </mat-menu>
    </ng-container>

    <ng-container *ngIf="authState.isLoggedIn">
      <app-notifications></app-notifications>
      <img [src]="authState.user.photoURL" alt="avatar" class="auth-avatar" [routerLink]="'/organizations'"/>
      <button (click)="signOut()" mat-button class="auth-button">{{ t('actions.signOut') }}</button>
    </ng-container>
  </li>
</ng-container>
