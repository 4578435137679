import { Component, Input } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/material/progress-spinner";
function PictureComponent_ng_container_1_source_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "source", 3);
} if (rf & 2) {
    const format_r74 = ctx.$implicit;
    const ctx_r73 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("type", "image/" + format_r74)("srcset", ctx_r73.srcset + "." + format_r74, i0.ɵɵsanitizeUrl);
} }
function PictureComponent_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, PictureComponent_ng_container_1_source_1_Template, 1, 2, "source", 2);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const ctx_r71 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngForOf", ctx_r71.formats);
} }
function PictureComponent_mat_spinner_3_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "mat-spinner");
} }
const _c0 = function () { return { width: "100%" }; };
const _c1 = function () { return { height: "100%" }; };
export class PictureComponent {
    constructor() {
        this.encapsulate = 'width';
        this.formats = ['png', 'webp'];
        this.external = false;
        this.showSpinner = false;
        this.alt = 'picture';
        this.loading = true;
    }
    ngOnInit() {
    }
    presentImage() {
        this.loading = false;
    }
}
PictureComponent.ɵfac = function PictureComponent_Factory(t) { return new (t || PictureComponent)(); };
PictureComponent.ɵcmp = i0.ɵɵdefineComponent({ type: PictureComponent, selectors: [["app-picture"]], inputs: { srcset: "srcset", src: "src", encapsulate: "encapsulate", formats: "formats", external: "external", showSpinner: "showSpinner", alt: "alt" }, decls: 4, vars: 9, consts: [[4, "ngIf"], [3, "src", "alt", "load"], [3, "type", "srcset", 4, "ngFor", "ngForOf"], [3, "type", "srcset"]], template: function PictureComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "picture");
        i0.ɵɵtemplate(1, PictureComponent_ng_container_1_Template, 2, 1, "ng-container", 0);
        i0.ɵɵelementStart(2, "img", 1);
        i0.ɵɵlistener("load", function PictureComponent_Template_img_load_2_listener($event) { return ctx.presentImage(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵtemplate(3, PictureComponent_mat_spinner_3_Template, 1, 0, "mat-spinner", 0);
    } if (rf & 2) {
        i0.ɵɵclassProp("loading", ctx.loading);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", !ctx.external);
        i0.ɵɵadvance(1);
        i0.ɵɵstyleSanitizer(i0.ɵɵdefaultStyleSanitizer);
        i0.ɵɵstyleMap(ctx.encapsulate == "width" ? i0.ɵɵpureFunction0(7, _c0) : i0.ɵɵpureFunction0(8, _c1));
        i0.ɵɵproperty("src", ctx.src || ctx.srcset + "." + ctx.formats[ctx.formats.length - 1], i0.ɵɵsanitizeUrl)("alt", ctx.alt);
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngIf", ctx.loading && ctx.showSpinner);
    } }, directives: [i1.NgIf, i1.NgForOf, i2.MatSpinner], styles: ["picture[_ngcontent-%COMP%]{-webkit-transition:.2s;transition:all .2s ease}picture.loading[_ngcontent-%COMP%]{background-color:#eceff1;opacity:0}img[_ngcontent-%COMP%]{display:block}mat-spinner[_ngcontent-%COMP%]{margin:auto}"] });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(PictureComponent, [{
        type: Component,
        args: [{
                selector: 'app-picture',
                templateUrl: './picture.component.html',
                styleUrls: ['./picture.component.scss']
            }]
    }], function () { return []; }, { srcset: [{
            type: Input
        }], src: [{
            type: Input
        }], encapsulate: [{
            type: Input
        }], formats: [{
            type: Input
        }], external: [{
            type: Input
        }], showSpinner: [{
            type: Input
        }], alt: [{
            type: Input
        }] }); })();
