import {Component, Input} from '@angular/core';
import { ConnectorProviderModel} from '../../../../../shared/types/connector-provider';
import {ConnectorModel} from '../../../../../shared/types/connector/connector.model';

@Component({
  selector: 'app-provider-card',
  templateUrl: './provider-card.component.html',
  styleUrls: ['./provider-card.component.scss']
})
export class ProviderCardComponent {

  @Input() provider: ConnectorProviderModel | ConnectorModel;
  @Input() counter: any = {};

}
