<footer *transloco="let t; read: 'app.footer'">
  <div class="container">

    <!-- Copyrights -->
    <span id="copyrights">{{ t('copyrights') }}</span>

    <!-- Web App links -->
    <div id="links">
      <a *ngFor="let link of links" [href]="link.href">{{ t('links.' + link.text) }}</a>
    </div>

    <!-- Phone number -->
    <div id="call">
      <span>{{ t('call.text') }}</span>
      <div>
        <app-picture srcset="assets/images/us-flag" alt="us-flag"></app-picture>
        <span>{{ t('call.phone') }}</span>
      </div>
    </div>

    <!-- Socials -->
    <div id="socials">
      <a *ngFor="let link of socials" [href]="link.href" class="social {{ link.icon }}">
        <fa-icon [icon]="['fab', link.icon]"></fa-icon>
      </a>
    </div>

  </div>
</footer>
