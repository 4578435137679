import { Inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import { NGXS_PLUGINS } from '@ngxs/store';
import { pick } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/fire/analytics";
export const NGXS_INTERCEPTOR_PLUGIN_OPTIONS = new InjectionToken('NGXS_INTERCEPTOR_PLUGIN_OPTIONS');
export class InterceptorPlugin {
    constructor(options, afAuth, analytics) {
        this.options = options;
        this.afAuth = afAuth;
        this.analytics = analytics;
        this.ignoredActions = [
            '@@INIT',
            '@@UPDATE_STATE',
            '[Forms] Update Form Dirty',
            '[Forms] Update Form Errors',
            '[Forms] Update Form Status',
            '[Forms] Update Form Value',
            // '[Router] Navigate',
            '[Router] RouterCancel',
            // '[Router] RouterNavigation',
            '[Router] RouterDataResolved'
        ];
    }
    /**
     * TODO docs
     * @param before
     * @param action
     * @param next
     */
    handle(before, action, next) {
        const currentUid = before.auth && before.auth.isLoggedIn && before.auth.user ? before.auth.user.uid : undefined;
        const currentOrganizationId = before.organizations && before.organizations.selectedId ? before.organizations.selectedId : undefined;
        // Identifying variables for user and organization
        const extraParams = { hub_user_id: currentUid, hub_organization_id: currentOrganizationId };
        const type = action.type || action.constructor.type;
        const params = this.getEventParams(action, type);
        if (!this.ignoredActions.includes(type)) {
            this.analytics.logEvent(type, Object.assign(Object.assign({}, extraParams), params))
                // .then(() => console.debug('logEvent', {type, params}))
                .catch(err => console.error(err));
        }
        return next(before, action);
    }
    /**
     * TODO docs
     * @param action
     * @param type
     */
    getEventParams(action, type) {
        if (type.includes('[Router]')) {
            return pick(action.event, ['id', 'url', 'urlAfterRedirects']);
        }
        if (action.constructor.eventParams) {
            return pick(action, action.constructor.eventParams);
        }
        return {};
    }
}
InterceptorPlugin.ɵfac = function InterceptorPlugin_Factory(t) { return new (t || InterceptorPlugin)(i0.ɵɵinject(NGXS_INTERCEPTOR_PLUGIN_OPTIONS), i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.AngularFireAnalytics)); };
InterceptorPlugin.ɵprov = i0.ɵɵdefineInjectable({ token: InterceptorPlugin, factory: InterceptorPlugin.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(InterceptorPlugin, [{
        type: Injectable
    }], function () { return [{ type: undefined, decorators: [{
                type: Inject,
                args: [NGXS_INTERCEPTOR_PLUGIN_OPTIONS]
            }] }, { type: i1.AngularFireAuth }, { type: i2.AngularFireAnalytics }]; }, null); })();
export class NgxsInterceptorPluginModule {
    static forRoot(config) {
        return {
            ngModule: NgxsInterceptorPluginModule,
            providers: [
                {
                    provide: NGXS_PLUGINS,
                    useClass: InterceptorPlugin,
                    multi: true
                },
                {
                    provide: NGXS_INTERCEPTOR_PLUGIN_OPTIONS,
                    useValue: config
                }
            ]
        };
    }
}
NgxsInterceptorPluginModule.ɵmod = i0.ɵɵdefineNgModule({ type: NgxsInterceptorPluginModule });
NgxsInterceptorPluginModule.ɵinj = i0.ɵɵdefineInjector({ factory: function NgxsInterceptorPluginModule_Factory(t) { return new (t || NgxsInterceptorPluginModule)(); } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NgxsInterceptorPluginModule, [{
        type: NgModule
    }], null, null); })();
