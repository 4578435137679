<ng-container *ngIf="logs$ | async as logs; else loadingLogs">
  <ng-container *ngIf="logs.isInitialized else loadingLogs">
    <app-logs-table [logs]="logs.collection"></app-logs-table>
  </ng-container>
</ng-container>


<ng-template #loadingLogs>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</ng-template>
