import { __awaiter, __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { Action, State } from '@ngxs/store';
import { DisplayError, ResetError, StartLoading, StopLoading } from '@store/app/app.actions';
import * as i0 from "@angular/core";
const initialState = {
    isLoading: false,
    error: null
};
let AppState = class AppState {
    startLoading({ patchState }) {
        return __awaiter(this, void 0, void 0, function* () {
            return patchState({ isLoading: true });
        });
    }
    stopLoading({ patchState }) {
        return __awaiter(this, void 0, void 0, function* () {
            return patchState({ isLoading: false });
        });
    }
    displayError({ patchState }, { message }) {
        return __awaiter(this, void 0, void 0, function* () {
            return patchState({ error: message });
        });
    }
    resetError({ patchState }) {
        return __awaiter(this, void 0, void 0, function* () {
            return patchState({ error: null });
        });
    }
};
AppState.ɵfac = function AppState_Factory(t) { return new (t || AppState)(); };
AppState.ɵprov = i0.ɵɵdefineInjectable({ token: AppState, factory: AppState.ɵfac });
__decorate([
    Action(StartLoading)
], AppState.prototype, "startLoading", null);
__decorate([
    Action(StopLoading)
], AppState.prototype, "stopLoading", null);
__decorate([
    Action(DisplayError)
], AppState.prototype, "displayError", null);
__decorate([
    Action(ResetError)
], AppState.prototype, "resetError", null);
AppState = __decorate([
    State({
        name: 'app',
        defaults: initialState
    })
], AppState);
export { AppState };
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AppState, [{
        type: Injectable
    }], null, { startLoading: [], stopLoading: [], displayError: [], resetError: [] }); })();
