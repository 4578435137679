<div id="logs-table" *transloco="let t; read: 'app.logsDialog.logsTable'">
  <table mat-table [dataSource]="dataSource" matSort>
    <!-- ID Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>{{ t('type') }}</th>
      <td mat-cell *matCellDef="let log">{{ t(log.type, log.params)}}</td>
    </ng-container>

    <!-- Progress Column -->
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ t('createdAt') }}</th>
      <td mat-cell *matCellDef="let log" class="date-column">{{log.createdAt | date: 'medium' }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" id="table-columns"></tr>
    <tr mat-row *matRowDef="let log; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
