import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.component.scss']
})
export class PictureComponent implements OnInit {
  @Input() srcset: string;
  @Input() src: string;
  @Input() encapsulate: string = 'width';
  @Input() formats: string[] = ['png', 'webp'];
  @Input() external = false;
  @Input() showSpinner = false;
  @Input() alt = 'picture';

  loading: boolean = true;

  constructor() {
  }

  ngOnInit() {
  }

  presentImage() {
    this.loading = false;
  }
}
