<div *transloco="let t; read: 'app.dialogs.confirm'" [hidden]="isLoading">
  <h2 mat-dialog-title>{{ title || t('title') }}</h2>

  <mat-dialog-content>
    <p>{{ content || t('content') }}</p>
  </mat-dialog-content>

  <mat-dialog-actions class="align-end">
    <ng-container *ngFor="let action of actions">
      <button *ngIf="action.primary" mat-flat-button color="warn"
              (click)="action.callback ? action.callback() : null">{{ t('actions.' + action.value) }}</button>
      <button *ngIf="!action.primary" mat-stroked-button
              (click)="action.callback ? action.callback() : null">{{ t('actions.' + action.value) }}</button>
    </ng-container>
  </mat-dialog-actions>
</div>

<div class="loading" [hidden]="!isLoading">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
