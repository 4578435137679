import { Injectable } from '@angular/core';
import { auth } from 'firebase/app';
import { of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@shared/pipes/auth/user.pipe";
import * as i3 from "@angular/fire/firestore";
import * as i4 from "@angular/fire/analytics";
export class AuthService {
    constructor(afAuth, userPipe, afs, analytics) {
        this.afAuth = afAuth;
        this.userPipe = userPipe;
        this.afs = afs;
        this.analytics = analytics;
    }
    get uid() {
        return this.afAuth.auth.currentUser.uid;
    }
    /**
     * Get the current logged in authenticated user
     */
    get user$() {
        return this.afAuth.authState.pipe(switchMap((firebaseUser) => {
            return !firebaseUser
                ? of(null)
                : this.getDoc$(firebaseUser).pipe(filter(doc => Boolean(doc)), map(doc => this.mapToModel(doc, firebaseUser)), tap(user => this.trackUserAnalytics(user)));
        }));
    }
    isAuthenticated() {
        return this.afAuth.authState.pipe(map(firebaseUser => Boolean(firebaseUser)));
    }
    /**
     * Signs in the user via Google Provider
     */
    signInWithGoogle(isPopup) {
        const googleAuth = new auth.GoogleAuthProvider();
        if (isPopup) {
            return this.afAuth.auth.signInWithPopup(googleAuth);
        }
        return this.afAuth.auth.signInWithRedirect(googleAuth);
    }
    /**
     * Logs the user out
     */
    signOut() {
        return this.afAuth.auth.signOut();
    }
    /**
     * Get the document of the current active user
     * @param firebaseUser
     */
    getDoc$(firebaseUser) {
        return this.afs.doc(`users/${firebaseUser.uid}`).valueChanges();
    }
    /**
     * Map `UserDocument` and `User` to `UserModel`
     * @param doc
     * @param firebaseUser
     */
    mapToModel(doc, firebaseUser) {
        return this.userPipe.transform({ firebaseUser, doc });
    }
    /**
     * Set UserId for analytics
     * @param user
     */
    trackUserAnalytics(user) {
        return this.analytics.setUserId(user.uid);
    }
}
AuthService.ɵfac = function AuthService_Factory(t) { return new (t || AuthService)(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.UserPipe), i0.ɵɵinject(i3.AngularFirestore), i0.ɵɵinject(i4.AngularFireAnalytics)); };
AuthService.ɵprov = i0.ɵɵdefineInjectable({ token: AuthService, factory: AuthService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(AuthService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i1.AngularFireAuth }, { type: i2.UserPipe }, { type: i3.AngularFirestore }, { type: i4.AngularFireAnalytics }]; }, null); })();
