import {Dictionary} from '../../interfaces/dictionary.interface';

export function flatten(obj: Dictionary, includeArrays = false): Dictionary<string | number | Date> {
  const newObj: Dictionary = {};
  Object.keys(obj).forEach(k => {
    const v = obj[k];

    if (!v) {
      return;
    }

    if (Array.isArray(v)) {
      if (includeArrays) {
        newObj[k] = v;
      }
      return;
    }

    if (typeof v === 'object' && !(v instanceof Date)) {
      const f = flatten(v);
      Object.keys(f).forEach(k2 => {
        newObj[k + '_' + k2] = f[k2];
      });
    } else {
      newObj[k] = v;
    }
  });

  return newObj;
}
